import React,{useEffect, useState} from 'react';
import bgImg from './images/physiotherapy.jpg';
import image1Img from './images/image_1.jpg';
import image2Img from './images/image_2.jpg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import bgwhite from './images/bgwhite2.jpg';
import { IoMdFlower } from 'react-icons/io';
import axios from 'axios';
import { IoMdHappy } from 'react-icons/io';
import { IoMdStar } from 'react-icons/io';
import { IoMdMedkit } from 'react-icons/io';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FloatingIcons from './FloatingIcons';
import { IoLocationSharp, IoCallSharp, IoMailSharp, IoMapSharp, IoMedicalSharp } from 'react-icons/io5';
import { FaBars } from 'react-icons/fa';
import webicon from './images/websiteicon.png';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faClock } from "@fortawesome/free-solid-svg-icons";
import Loader from './Loader';
import '@fortawesome/fontawesome-free/css/all.min.css';

const firebaseConfig = {
  apiKey: "AIzaSyBKuOwNC3-wBj6Gmsx_YGVWV-a_qlX9aXw",
  authDomain: "activecare-8b7d9.firebaseapp.com",
  databaseURL: "https://activecare-8b7d9-default-rtdb.firebaseio.com",
  projectId: "activecare-8b7d9",
  storageBucket: "activecare-8b7d9.appspot.com",
  messagingSenderId: "1028559468034",
  appId: "1:1028559468034:web:e87174fa0ed2459839991b",
  measurementId: "G-ZJETJE99MW"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();


const categories = ["Pain Management", " Rehabilitation", "Specialized Therapy", "General Wellness", "Manual Therapy"];


const Services=()=>{

  
  const [isAdmin, setIsAdmin] = useState(false);

  
  useEffect(()=>{
    const checkAdminStatus=()=>{
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        const userEmail=user.email;
        firebase.database().ref('Admin').orderByValue().equalTo(userEmail).once('value')
          .then((snapshot)=>{
            if (snapshot.exists()) {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
          }).catch((error)=>{
            console.error("Error checking admin status:",error);
          })
      }else{
        setIsAdmin(false);
      }
  
    })
    }
    checkAdminStatus();
  },[]);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const [brandName, setBrandName] = useState('');

  useEffect(() => {
    const fetchBrandName = async () => {
      try {
        const snapshot = await firebase.database().ref('Title/Clinic').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setBrandName(data);
        } else {
          console.error('Brand name not found in database');
        }
      } catch (error) {
        console.error('Error fetching brand name:', error);
      }
    };

    fetchBrandName();
  }, []);

  
  useEffect(() => {
  
    $('nav .dropdown').hover(function(){
      var $this = $(this);
      // 	 timer;
      // clearTimeout(timer);
      $this.addClass('show');
      $this.find('> a').attr('aria-expanded', true);
      // $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
      $this.find('.dropdown-menu').addClass('show');
    }, function(){
      var $this = $(this);
        // timer;
      // timer = setTimeout(function(){
        $this.removeClass('show');
        $this.find('> a').attr('aria-expanded', false);
        // $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
        $this.find('.dropdown-menu').removeClass('show');
      // }, 100);
    });
  
  
    $('#dropdown04').on('show.bs.dropdown', function () {
      console.log('show');
    });
  
    // scroll
    var scrollWindow = function() {
      $(window).scroll(function(){
        var $w = $(this),
            st = $w.scrollTop(),
            navbar = $('.ftco_navbar'),
            sd = $('.js-scroll-wrap');
  
        if (st > 150) {
          if ( !navbar.hasClass('scrolled') ) {
            navbar.addClass('scrolled');	
          }
        } 
        if (st < 150) {
          if ( navbar.hasClass('scrolled') ) {
            navbar.removeClass('scrolled sleep');
          }
        } 
        if ( st > 350 ) {
          if ( !navbar.hasClass('awake') ) {
            navbar.addClass('awake');	
          }
          
          if(sd.length > 0) {
            sd.addClass('sleep');
          }
        }
        if ( st < 350 ) {
          if ( navbar.hasClass('awake') ) {
            navbar.removeClass('awake');
            navbar.addClass('sleep');
          }
          if(sd.length > 0) {
            sd.removeClass('sleep');
          }
        }
      });
    };
    scrollWindow();
},[]);


  //user icon
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  
    //isloggedin
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Check if user is already logged in
    const unsubscribe=firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
    return unsubscribe
  }, []);
  
  //logout
  const handleLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        setIsLoggedIn(false);
        // Redirect to home page or do any necessary clean up
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };


  

  
//achievement value
const [achievements, setAchievements] = useState([]);
const [counts, setCounts] = useState({});

  useEffect(() => {
    const fetchAchievements = async () => {
      const database = firebase.database();
      const achievementsRef = database.ref('Achievement');

      try {
        const snapshot = await achievementsRef.once('value');
        const data = snapshot.val();
        const achievementsArray = Object.values(data).filter(item => typeof item === 'object');
        setAchievements(achievementsArray);

        const initialCounts = {};
        achievementsArray.forEach(achievement => {
          initialCounts[achievement.Achievement_id] = 0;
        });
        setCounts(initialCounts);

        
        
      } catch (error) {
        console.error('Error fetching achievements:', error);
      }
    };

    fetchAchievements();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounts(prevCounts => {
        const updatedCounts = {};
        achievements.forEach(achievement => {
          const { Achievement_id, Achievement_value } = achievement;
          const increment = 500;
          updatedCounts[Achievement_id] = Math.min(prevCounts[Achievement_id]  + increment, Achievement_value);
        });
        return updatedCounts;
      });
    }, 100); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, [achievements]);


  const [achievementData1, setAchievementData1] = useState(null);

  useEffect(() => {
    const fetchAchievementData = async () => {
      const database = firebase.database();
      const achievementRef = database.ref('Achievement');

      try {
        const snapshot = await achievementRef.once('value');
        const data = snapshot.val();
        setAchievementData1(data);
      } catch (error) {
        console.error('Error fetching achievement data:', error);
      }
    };

    fetchAchievementData();
  }, []);

  
  

  //SEO
  
  const [metaTitle, setMetaTitle] = useState('');
  const [keywords, setKeywords] = useState('');
  const [primaryKeywords, setPrimaryKeywords] = useState('');
  const [secondaryKeywords, setSecondaryKeywords] = useState('');
  const [message3, setMessage3] = useState(''); 


useEffect(() => {
    const metaDataRef = firebase.database().ref('metadata');
  
    // Fetch metadata from the database
    metaDataRef.once('value').then((snapshot) => {
        const metaData = snapshot.val();
        if (metaData) {
            setMetaTitle(metaData.metaTitle || '');
            setKeywords(metaData.keywords || '');
            setPrimaryKeywords(metaData.primaryKeywords || '');
            setSecondaryKeywords(metaData.secondaryKeywords || '');
  
        }
    }).catch((error) => {
        console.error('Error fetching metadata:', error);
    });
  }, []);
  useEffect(() => {

  

    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const primaryKeywordsMeta = document.querySelector('meta[name="primary keywords"]');
    const secondaryKeywordsMeta = document.querySelector('meta[name="secondary keywords"]');

    console.log('Keywords meta content:', keywordsMeta ? keywordsMeta.content : null);
    console.log('Primary keywords meta content:', primaryKeywordsMeta ? primaryKeywordsMeta.content : null);
    console.log('Secondary keywords meta content:', secondaryKeywordsMeta ? secondaryKeywordsMeta.content : null);

    if (keywordsMeta) {
        keywordsMeta.setAttribute('content', keywords);
    }

    if (primaryKeywordsMeta) {
        primaryKeywordsMeta.setAttribute('content', primaryKeywords);
    }

    if (secondaryKeywordsMeta) {
        secondaryKeywordsMeta.setAttribute('content', secondaryKeywords);
    }
}, [metaTitle,keywords, primaryKeywords, secondaryKeywords]);


  
  //footer address change
  const [officeInfo, setOfficeInfo] = useState({
    email: '',
    phoneNumber: '',
    address: ''
  });

  useEffect(() => {
    const fetchOfficeInfo = async () => {
      try {
        const snapshot = await firebase.database().ref('Address').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setOfficeInfo({
            email: data.Address_email || '',
            phoneNumber: data.Address_number || '',
            phoneNumber1: data.Address_number1 || '',
            address: data.Address_place || ''
          });
        } else {
          console.error('Office information not found in database');
        }
      } catch (error) {
        console.error('Error fetching office information:', error);
      }
    };

    fetchOfficeInfo();
  }, []);


  


   //appointment form
   const [doctorName, setDoctorName] = useState('');
   const [doctorOptions, setDoctorOptions] = useState([]);
 
   
   useEffect(() => {
    const fetchDoctorOptions = () => {
      const doctorRef = db.ref('doctors_name');
      doctorRef.once('value', (snapshot) => {
        const doctors = snapshot.val();
        if (doctors) {
          const doctorNames = Object.values(doctors);
          setDoctorOptions(doctorNames);
        }
      });
    };
    fetchDoctorOptions();
  }, [db]);

  const [formData, setFormData] = useState({
    Appointmenttab_id: 0, // Initial id value
    Appointmenttab_name: '',
    Appointmenttab_email: '',
    Appointmenttab_date: new Date(),
    Appointmenttab_time: '', // Default time
    Appointmenttab_doctorname: '', 
    Appointmenttab_phone: ''
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [timeOptions1, setTimeOptions1] = useState([]);

  useEffect(() => {
    // Fetch the latest appointment count from the database
    const appointmentsRef = db.ref('Appointment_tab');
    appointmentsRef.once('value', (snapshot) => {
      const appointments = snapshot.val();
      // Get the count of existing appointments
      const count = appointments ? Object.keys(appointments).length : 0;
      setAppointmentCount(count);
    });
  }, []);

  
  
  const defaultTimeOptions = [
    "10:00 AM - 10:30 AM",
    "10:30 AM - 11:00 AM",
    "11:00 AM - 11:30 AM",
    "11:30 AM - 12:00 AM",
    "12:00 PM - 12:30 PM",
    "12:30 PM - 01:00 PM",
    "04:00 PM - 04:30 PM",
    "04:30 PM - 05:00 PM",
    "05:00 PM - 05:30 PM",
    "05:30 PM - 06:00 PM",
    "06:00 PM - 06:30 PM",
    "06:30 PM - 07:00 PM",
    "07:00 PM - 07:30 PM",
    "07:30 PM - 08:00 PM",
  ];
  

  useEffect(() => {
    const fetchTimeOptions = () => {
      if (formData.Appointmenttab_date) {
        const adminRef = db.ref('adminData');
        adminRef.once('value', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const timeRanges = Object.values(data)
              .filter((item) => item.date === formData.Appointmenttab_date.toLocaleDateString('en-US'))
              .map((item) => item.timeRange);
              setTimeOptions1([...defaultTimeOptions, ...timeRanges]);
          }
        });
      }
    };
    fetchTimeOptions();
  }, [formData.Appointmenttab_doctorname, formData.Appointmenttab_date, db]);
  
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.toISOString() : '';

    setFormData({ ...formData, Appointmenttab_date: formattedDate });
  };

  
  const handleTimeChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, Appointmenttab_time: value });
  };

  const timeOptions = (() => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(time);
      }
    }
    return options;
  })();

  const handleDoctorNameChange = (e) => {
    const { value } = e.target;
    setDoctorName(value); // Update doctorName state
    setFormData({ ...formData, Appointmenttab_doctorname: value }); // Update formData state
  };
  

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Store form data in Realtime Database

    try {
      const snapshot = await db.ref('Appointment_tab').orderByChild('Appointmenttab_id').limitToLast(1).once('value');
      let latestAppointment = null;
      snapshot.forEach((childSnapshot) => {
        latestAppointment = childSnapshot.val();
      });

      console.log("Latest appointment:", latestAppointment);

      // Calculate the new ID
      const newAppointmentId = latestAppointment ? latestAppointment.Appointmenttab_id + 1 : 1;

      console.log("New appointment ID:", newAppointmentId);
      const updatedFormData = {
        ...formData,
        Appointmenttab_id: newAppointmentId,
        Appointmenttab_doctorname: 'Dr. Senthil Nathan',
      };

      updatedFormData.Appointmenttab_date = new Date(updatedFormData.Appointmenttab_date).toISOString();

      await db.ref('Appointment_tab').push(updatedFormData);

      console.log('Sending email with data:', {
        name: formData.Appointmenttab_name,
        date: new Date(formData.Appointmenttab_date).toLocaleString(), 
        time: formData.Appointmenttab_time,
        doctorName: formData.Appointmenttab_doctorname,
        phone: formData.Appointmenttab_phone,
        email: formData.Appointmenttab_email,
      });

      const emailData = {
        name: formData.Appointmenttab_name,
        date: formData.Appointmenttab_date,
        time: formData.Appointmenttab_time,
        doctorName: "Dr.Senthil Nathan",
        phone: formData.Appointmenttab_phone,
        email: formData.Appointmenttab_email,
        defaultemail: "ActiveCarePhysio22@gmail.com"
      };

      console.log('Sending email with data:', emailData); 

      const response = await axios.post('https://us-central1-activecare-8b7d9.cloudfunctions.net/api/send-email', emailData);

      if (response.status !== 200) {
        throw new Error('Failed to send email');
      }

      setFormData({
        Appointmenttab_id: '',
        Appointmenttab_name: '',
        Appointmenttab_email: '',
        Appointmenttab_date: new Date(),
        Appointmenttab_time: '',
        Appointmenttab_doctorname: '',
        Appointmenttab_phone: ''
      });
      setSuccessMessage('Appointment successfully scheduled!');
      setTimeout(() => {
        setSuccessMessage('');
        setModalVisible(false);
      }, 2000);
    } catch (error) {
      console.error('Error storing form data:', error);
    }
  };
   



  const [services, setServices] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchServices = async () => {
      const db = firebase.database().ref("Services");
      db.once('value').then(snapshot => {
        setServices(snapshot.val());
      });
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 13000);

    return () => clearInterval(interval);
  }, []);

  const activeCategory = categories[activeIndex];


  
  useEffect(()=>{
    document.title="Services | Active Care Physiotherapy & Sports Injury Clinic"
  },[]);

    return(
        <>
  <title>DentaCare - Free Bootstrap 4 Template by Colorlib</title>
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700"
    rel="stylesheet"
  />
  <link rel="stylesheet" href="css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="css/animate.css" />
  <link rel="stylesheet" href="css/owl.carousel.min.css" />
  <link rel="stylesheet" href="css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="css/magnific-popup.css" />
  <link rel="stylesheet" href="css/aos.css" />
  <link rel="stylesheet" href="css/ionicons.min.css" />
  <link rel="stylesheet" href="css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="css/jquery.timepicker.css" />
  <link rel="stylesheet" href="css/flaticon.css" />
  <link rel="stylesheet" href="css/icomoon.css" />
  <link rel="stylesheet" href="css/style.css" />
  <header>
  <nav
    className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
    id="ftco-navbar"
  >
    <div className="container">
    <img className="icoimage" src={webicon} alt="Website Icon" style={{ width: '80px' }} />
    <div className='column'>
    <a className="navbar-brand" href="/" style={{color:"black"}}>
      
      <span className="desktop-view">
      ACTIVECARE PHYSIOTHERAPY & SPORTS INJURY CLINIC
      <br />
      <span className="slogan">Your Pain Stops Here</span>
    </span>
     <span className="mobile-view">
      ActiveCare Physiotherapy<br />&<br />Sports Injury Clinic
      <br />
      <span className="slogan">Your Pain Stops Here</span>
    </span>
</a>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#ftco-nav"
        aria-controls="ftco-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <FaBars />
      </button>
      <div className="collapse navbar-collapse" id="ftco-nav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a href="/" className="nav-link" style={{color:"black"}}>
              Home
            </a>
          </li>
         {/* <li className="nav-item">
            <a href="/pharmacy" className="nav-link">
              Pharmacy
            </a>
          </li>*/}
          <li className="nav-item">
            <a href="/about" className="nav-link" style={{color:"black"}}>
              About
            </a>
          </li>
          <li className="nav-item active">
            <a href="/services" className="nav-link" style={{color:"#A890FE"}}>
              Services
            </a>
          </li>
          <li className="nav-item">
            <a href="/doctors" className="nav-link" style={{color:"black"}}>
              Doctors
            </a>
          </li>
          <li className="nav-item">
            <a href="/contact" className="nav-link" style={{color:"black"}}>
              Contact
            </a>
          </li>
          <li className="nav-item cta">
            <a
              
              className="nav-link"
              data-toggle="modal"
              data-target="#modalRequest"
              onClick={() => setModalVisible(true)}
            >
              <span>Appointment</span>
            </a>
          </li>
          <div className="quote_btn-container">
      <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <FontAwesomeIcon
          icon={faUser}
          aria-hidden="true"
          style={{ marginRight: '50px', cursor: 'pointer', marginTop: '15px', marginLeft: '15px', color: '#EA8D8D' }}
        />
        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ backgroundColor: 'white', border: '1px solid black',width: '10', maxHeight: '200px', overflowY: 'auto' }}>
          {isLoggedIn ? (
            <>
              <Link to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }} hover={{backgroundColor:"#007bff"}}>Profile</Link>
             {isAdmin &&<Link to="/seo-settings" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>SEO Settings</Link>}
             {isAdmin &&<Link to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Admin Page</Link>}
              <Link to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Logout</Link>
            </>
          ) : (
            <Link to="/signin" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Login</Link>
          )}
        </div>
      </div>
    </div>
        </ul>
      </div>
    </div>
  </nav>
  </header>
  <Loader/>
  {/* END nav */}
  <section className="home-slider">
    <div
      className="slider-item bread-item"
      style={{ backgroundImage: `url(${bgImg})` }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay" />
      <div className="container" data-scrollax-parent="true">
        <div className="row slider-text align-items-end">
          <div className="col-md-7 col-sm-12 ftco-animate mb-5">
            <p
              className="breadcrumbs"
              data-scrollax=" properties: { translateY: '70%', opacity: 1.6}"
            >
              <span className="mr-2">
                <a href="/" style={{color:"white"}}>Home</a>
              </span>{" "}
              <span style={{color:"#301934"}}>Services</span>
            </p>
            <h1
              className="mb-3"
              data-scrollax=" properties: { translateY: '70%', opacity: .9}"
            >
              Our Service Keeps you Smile
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section">
    <div className="container">
      <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
          <h2 className="mb-2">Our Service Keeps you Smile</h2>
          <p>
          Our best physiotherapy services to the patients including fitness management program, deep tissue massages, acupuncture, pain relief therapy, electro-therapy, skilled manual therapy etc.
          </p>
        </div>
      </div>
      <div className="container">
      <div id="tabs" className="d-flex justify-content-center mb-4 tabs-container">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`tab tabts  ${index === activeIndex ? 'active' : ''}`}
            onClick={() => setActiveIndex(index)}
            style={{
              cursor: 'pointer',
              padding: '10px 20px',
              margin: '0 5px',
              backgroundColor: index === activeIndex ? '#A890FE' : 'white',
              color: index === activeIndex ? '#fff' : '#000',
            }}
          >
            {category}
          </div>
        ))}
      </div><br></br><br></br>

      <div id="content">
        {services[activeCategory] && (
          <div className="row">
            {services[activeCategory].slice(1).map((item) => (
              <div key={item.id} className="col-md-3 d-flex align-self-stretch ftco-animate">
                <div className="media block-6 services d-block text-center">
                  <div className="icon d-flex justify-content-center align-items-center" dangerouslySetInnerHTML={{ __html: item.icon }} />
                    
                 
                  <div className="media-body p-2 mt-3">
                    <h3 className="heading">{item.Name}</h3>
                    <p className='text-justify'>{item.Value}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <style jsx>{`
        .tab {
          display: inline-block;
          margin: 10px;
          padding: 10px 20px;
          background-color: white;
          cursor: pointer;
          border-radius: 10px;
          border: 2px solid #A890FE;
          border-color: #A890FE;
        }
        .tab.active {
          background-color: #007bff;
          color: white;
        }
        .content {
          display: none;
          margin-top: 20px;
        }
        .content.active {
          display: block;
        }
      `}</style>
    </div>
    
    {/*}
      <div className="row">
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-tooth" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Teeth Whitening</h3>
              <p className='text-justify'>
              Teeth whitening is a cosmetic dental procedure aimed at lightening the color of teeth. It helps to remove stains and discoloration, resulting in a brighter smile. 
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dental-care" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Teeth Cleaning</h3>
              <p className='text-justify'>
              Teeth cleaning, also known as prophylaxis, is a routine dental procedure performed to remove plaque, tartar, and stains from the teeth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-tooth-with-braces" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Quality Brackets</h3>
              <p className='text-justify'>
              Quality brackets, often used in orthodontic treatments such as braces, are essential components that hold the wires in place and exert pressure on the teeth to correct their alignment.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-anesthesia" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Modern Anesthetic</h3>
              <p className='text-justify'>
              Modern anesthetics used in dentistry provide effective pain management and comfort during dental procedures. 
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dental-care" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Dental Calculus</h3>
              <p className='text-justify'>
              Dental calculus, also known as tartar, is a hardened deposit that forms on the teeth due to the accumulation of plaque over time.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-bacteria" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Paradontosis</h3>
              <p className='text-justify'>
                Periodontosis, more commonly referred to as periodontal disease or gum disease, is a chronic inflammatory condition that affects the gums and supporting structures of the teeth. 
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dentist" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Dental Implants</h3>
              <p className='text-justify'>
              Dental implants are titanium posts that are surgically placed into the jawbone to replace missing teeth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dental-care-1" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Tooth Braces</h3>
              <p className='text-justify'>
              Tooth braces, commonly known as braces, are orthodontic devices used to straighten and align teeth, correct bite issues, and improve overall dental alignment. 
              </p>
            </div>
          </div>
        </div>
      </div>
      */}
    </div>
  </section>
  <section
    className="ftco-section ftco-counter img"
    id="section-counter"
    style={{ backgroundImage: `url(${bgwhite})` }}
    data-stellar-background-ratio="0.5"
  >
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-md-3 aside-stretch py-5">
          <div className=" heading-section heading-section-white ftco-animate pr-md-4">
            <h2 className="mb-3">{achievementData1?.Achievement_title}</h2>
            <p style={{textAlign:"justify"}}>
            {achievementData1?.Achievement_description}
            </p>
          </div>
        </div>
        {/* <div className="col-md-9 py-5 pl-md-5">
          <div className="row">
          {achievements.map((achievement, index) => (
            <div key={index} className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                {achievement.Achievement_icon === 'flower' && <IoMdFlower style={{ color: '#A890FE', fontSize: '60px' }} />}
                {achievement.Achievement_icon === 'happy' && <IoMdHappy style={{ color: '#A890FE', fontSize: '60px' }} />}
                {achievement.Achievement_icon === 'star' && <IoMdStar style={{ color: '#A890FE', fontSize: '60px' }} />}
                {achievement.Achievement_icon === 'medkit' && <IoMdMedkit style={{ color: '#A890FE', fontSize: '60px' }} />}
                </div>
                <strong className="number" style={{ color: counts[achievement.Achievement_id] === 0 ? '#A890FE' : '#A890FE' }}>
                {counts[achievement.Achievement_id] !== undefined ? 
                      (achievement.Achievement_id !== 2 ? `${counts[achievement.Achievement_id]}+` : counts[achievement.Achievement_id])
                      : '0'}
                </strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>{achievement.Achievement_name}</span>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div> */}
        <div className="col-md-9 py-5 pl-md-5">
          <div className="row">
        
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdFlower style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>15+</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>Years of Experience</span>
                </div>
              </div>
            </div>
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdStar style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>5</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>Star Rated Physiotherapist</span>
                </div>
              </div>
            </div>
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdHappy style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>5000+</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>Number of Happy Patients Treated</span>
                </div>
              </div>
            </div>
            <div  className="col-6 col-md-3 d-flex justify-content-center mb-4">
              <div className="card text-center" style={{ width: '18rem', boxShadow: '0 4px 8px 0 rgba(168, 144, 254, 0.2)'  }}>
               <div className="card-body">
                <div className="icon d-flex justify-content-center align-items-center">
                  <IoMdMedkit style={{ color: '#A890FE', fontSize: '60px' }} />
                </div>
                <strong className='number' style={{ color:'#A890FE'}}>800+</strong><br></br>
                  <span className='numbervalue' style={{color:"black", textAlign:"justify"}}>New Visits every year</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section><br></br>
 
 
  
  <footer className="ftco-footer ftco-bg-dark ftco-section footer">
      <div className="container" style={{marginTop:"20px"}}>
        <div className="row mb-5">
          <div className="col-md-4">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2" style={{fontWeight:"bold"}}>{brandName || ''}</h2>
              <p style={{color:"black"}}>
                A fully equipped center for effective physiotherapy treatments. Our experienced and clinically versatile physiotherapist are immensely knowledgeable in clinical reasoning and hypothesizing the clinical impressions.
              </p>
            </div>
            <svg className="svg--source" width={0} height={0} aria-hidden="true">
           
            <symbol id="svg--twitter" viewBox="0 -7 15 30">
      <path d="M15.36 3.434c-0.542 0.241-1.124 0.402-1.735 0.476 0.624-0.374 1.103-0.966 1.328-1.67-0.583 0.346-1.23 0.598-1.917 0.733-0.551-0.587-1.336-0.954-2.205-0.954-1.668 0-3.020 1.352-3.020 3.019 0 0.237 0.026 0.467 0.078 0.688-2.51-0.126-4.735-1.328-6.224-3.155-0.261 0.446-0.41 0.965-0.41 1.518 0 1.048 0.534 1.972 1.344 2.514-0.495-0.016-0.961-0.151-1.368-0.378 0 0.013 0 0.025 0 0.038 0 1.463 1.042 2.683 2.422 2.962-0.253 0.069-0.52 0.106-0.796 0.106-0.194 0-0.383-0.018-0.568-0.054 0.384 1.2 1.5 2.073 2.821 2.097-1.034 0.81-2.335 1.293-3.75 1.293-0.244 0-0.484-0.014-0.72-0.042 1.336 0.857 2.923 1.357 4.63 1.357 5.554 0 8.592-4.602 8.592-8.593 0-0.13-0.002-0.261-0.009-0.39 0.59-0.426 1.102-0.958 1.507-1.563z" />
    </symbol>
           
            <symbol id="svg--google" viewBox="-13 -13 72 72">
              <path
                d="M48,22h-5v-5h-4v5h-5v4h5v5h4v-5h5 M16,21v6.24h8.72c-0.67,3.76-3.93,6.5-8.72,6.5c-5.28,0-9.57-4.47-9.57-9.75
        s4.29-9.74,9.57-9.74c2.38,0,4.51,0.82,6.19,2.42v0.01l4.51-4.51C23.93,9.59,20.32,8,16,8C7.16,8,0,15.16,0,24s7.16,16,16,16
        c9.24,0,15.36-6.5,15.36-15.64c0-1.17-0.11-2.29-0.31-3.36C31.05,21,16,21,16,21z"
              />
            </symbol>
            <symbol id="svg--facebook" viewBox="0 -7 16 30">
              <path d="M12 3.303h-2.285c-0.27 0-0.572 0.355-0.572 0.831v1.65h2.857v2.352h-2.857v7.064h-2.698v-7.063h-2.446v-2.353h2.446v-1.384c0-1.985 1.378-3.6 3.269-3.6h2.286v2.503z" />
            </symbol>
         
            
            <symbol id="svg--youtube" viewBox="-150 -150 800 800">
              <path
                d="M459,61.2C443.7,56.1,349.35,51,255,51c-94.35,0-188.7,5.1-204,10.2C10.2,73.95,0,163.2,0,255s10.2,181.05,51,193.8
              C66.3,453.9,160.65,459,255,459c94.35,0,188.7-5.1,204-10.2c40.8-12.75,51-102,51-193.8S499.8,73.95,459,61.2z M204,369.75v-229.5
              L357,255L204,369.75z"
              />
            </symbol>
          </svg>
          <div className="wrapper">
            <div className="connect">
              <a href="https://www.instagram.com/activecarephysiotherapy_omr/" className="share twitter">
              <i className="fab fa-instagram" style={{width:"30px",height:"10px",fontWeight:"bold",fontSize:"30px",marginTop:"7px", marginLeft:"10px"}}></i>

                <span className="clip">TWITTER</span>
              </a>
              <a href="https://maps.app.goo.gl/1sWcVQPMfMHd8cHf7" rel="author" className="share google">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--google" />
                </svg>
                <span className="clip">GOOGLE +</span>
              </a>
              <a href="https://www.facebook.com/profile.php?id=61562412287941" rel="author" className="share facebook">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--facebook" />
                </svg>
                <span className="clip">FACEBOOK</span>
              </a>
            
              <a href="" className="share  youtube">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--youtube" />
                </svg>
                <span className="clip">YOU-TUBE</span>
              </a>
            </div>
          </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center justify-content-md-start">
            <div className="ftco-footer-widget1 footer-duplicate mb-4">
              <h2 className="ftco-heading-2 mb-4" style={{fontWeight:"bold"}}>Address</h2>
              <div className="block-23 mb-4">
                <div className="row">
                  <div className="col-md-6">
                    <ul>
                      <li>
                        <span  style={{ color: "black" }}>
                         
                          {brandName}
                        </span>
                      </li>
                      <li>
                        <span className="text" style={{ color: "black" }}>
                          <IoLocationSharp style={{ marginRight: '8px' }} />
                          {officeInfo.address}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li>
                        <a className="text-center text-md-left">
                          <IoCallSharp style={{ marginRight: '8px' }} />
                          <span className="text" style={{ color: "black" }}>{officeInfo.phoneNumber},{officeInfo.phoneNumber1}</span>
                        </a>
                      </li>
                      <li>
                        <a className="text-center text-md-left">
                          <IoMailSharp style={{ marginRight: '8px' }} />
                          <span className="text" style={{ color: "black" }}>{officeInfo.email}</span>
                        </a>
                      </li>
                      <li>
                        <span className='text'>
                          <IoMapSharp style={{ marginRight: '8px', color: "black" }} />
                          <a href="https://maps.app.goo.gl/CPHkq33Ryk955HJ5A" style={{ color: "black" }}>Google Map</a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
            
              <div className="col-md-3 p-4 color-2card ftco-intro container2 " style={{background:"#8c6dfc", borderRadius:"10px"}}>
                <h3 className="mb-4"><FontAwesomeIcon icon={faClock} /> Opening Hours</h3>
                <hr></hr>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Monday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Tuesday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Wednesday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Thursday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Friday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Saturday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Sunday</span>
                  <span className="time">By Appointment</span>
                </p>
              </div>
            </div>
            
        
      
         <hr style={{color:"black"}}></hr>
          <div className="quick-links">
          <div className='footerq'>
          <p className="pfooter " style={{ color: "black",marginTop:"15px"}}>
            Designed & Developed by
            <a href="https://www.datadna.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#023E8A", marginLeft: '5px' }}>
              DATADNA
            </a>
          </p>
          </div>
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/services">Services</a>
            <a href="/doctors">Doctors</a>
            <a href="/contact">Contact</a>
          </div>
        </div>
        <div className="footer-icons-wrapper">
          <FloatingIcons isFooter={true} />
        </div>
      
    </footer>
  {/* loader */}
        {/* Modal */}
        {modalVisible && (
    <div className="modal" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="modalRequestLabel">Make an Appointment</h5>
        <button type="button" className="close" onClick={() => setModalVisible(false)}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" className="form-control" name="Appointmenttab_name" value={formData.Appointmenttab_name} onChange={handleInputChange} placeholder="Full Name" />
          </div>
          <div className="form-group">
            <input type="email" className="form-control" name="Appointmenttab_email" value={formData.Appointmenttab_email} onChange={handleInputChange} placeholder="Email" />
          </div>
          <div className="form-group">
            <input type="tel" className="form-control" name="Appointmenttab_phone" value={formData.Appointmenttab_phone} onChange={handleInputChange} placeholder="Phone Number" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group foalign">
                <DatePicker
                  selected={formData.Appointmenttab_date}
                  onChange={(date) => setFormData({ ...formData, Appointmenttab_date: date })}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  placeholderText="Select Date"
                  minDate={new Date()}
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" style={{right:"60px"}}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select className="form-control" name="Appointmenttab_time" value={formData.Appointmenttab_time} onChange={handleTimeChange} placeholder="Select Time">
                  <option value="">Select Time</option>
                  {timeOptions1.map((time, index) => (
                    <option key={index} value={time}>{time}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                      name="Appointmenttab_doctorname" value={formData.Appointmenttab_doctorname} onChange={handleDoctorNameChange} placeholder="Select Doctor"
                      >
                      {doctorOptions.map((doctor, index) => (
                        <option key={index} style={{ color: 'black' }}>{doctor}</option>
                      ))}
                </select>
                </div>
              </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">Make an Appointment</button>
          </div>
          {successMessage && <p className="text-success">{successMessage}</p>}
        </form>
      </div>
    </div>
  </div>
</div>

  )}
</>

    );
}
export default Services;