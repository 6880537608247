import logo from './logo.svg';
import './App.css';
import React,{useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Homepage from './homepage';
import About from './about';
import Contact from './contact';
import Doctors from './doctors';
import Services from './services';
import './style.css';
import Pharmacy from './Pharmacy';
import LoginForm from './signin';
import SignUpForm from './signup';
import Profile from './profile';
import SeoPage from './SEO';
import AdminPage from './adminpage';
import Confirmation from './confirmation';
import PaymentPage from './payment';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const App= () => {

  const [isAdmin, setIsAdmin] = useState(false);

  
  useEffect(()=>{
    const checkAdminStatus=()=>{
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        const userEmail=user.email;
        firebase.database().ref('Admin').orderByValue().equalTo(userEmail).once('value')
          .then((snapshot)=>{
            if (snapshot.exists()) {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
          }).catch((error)=>{
            console.error("Error checking admin status:",error);
          })
      }else{
        setIsAdmin(false);
      }
  
    })
    }
    checkAdminStatus();
  },[]);


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
       
        
        <Route path="/doctors" element={<Doctors/>}/>
       <Route path="/services" element={<Services/>}/>
        <Route path="/pharmacy" element={<Pharmacy/>}/>
        <Route path="/signin" element={<LoginForm/>}/>
        <Route path="/signup" element={<SignUpForm/>}/>
        <Route path="/profile" element={<Profile/>}/>
        {isAdmin &&<Route path="/seo-settings" element={<SeoPage/>}/>}
        {isAdmin &&<Route path="/adminpage" element={<AdminPage/>}/>}
        {/* <Route path="/confirmation" element={<Confirmation/>}/>
        <Route path="/payment" element={<PaymentPage/>}/> */}
        
      </Routes>
    </Router>
  );
}

export default App;
