import React,{useEffect, useState} from 'react';
import bgImg from './images/bg_1.jpg';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKuOwNC3-wBj6Gmsx_YGVWV-a_qlX9aXw",
  authDomain: "activecare-8b7d9.firebaseapp.com",
  databaseURL: "https://activecare-8b7d9-default-rtdb.firebaseio.com",
  projectId: "activecare-8b7d9",
  storageBucket: "activecare-8b7d9.appspot.com",
  messagingSenderId: "1028559468034",
  appId: "1:1028559468034:web:e87174fa0ed2459839991b",
  measurementId: "G-ZJETJE99MW"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();



const PaymentPage=()=>{

  //title name change
  const [brandName, setBrandName] = useState('');

  useEffect(() => {
    const fetchBrandName = async () => {
      try {
        const snapshot = await firebase.database().ref('Title/Clinic').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setBrandName(data);
        } else {
          console.error('Brand name not found in database');
        }
      } catch (error) {
        console.error('Error fetching brand name:', error);
      }
    };

    fetchBrandName();
  }, []);


  
  //footer address change
  const [officeInfo, setOfficeInfo] = useState({
    email: '',
    phoneNumber: '',
    address: ''
  });

  useEffect(() => {
    const fetchOfficeInfo = async () => {
      try {
        const snapshot = await firebase.database().ref('Address').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setOfficeInfo({
            email: data.Address_email || '',
            phoneNumber: data.Address_number || '',
            address: data.Address_place || ''
          });
        } else {
          console.error('Office information not found in database');
        }
      } catch (error) {
        console.error('Error fetching office information:', error);
      }
    };

    fetchOfficeInfo();
  }, []);

  
  useEffect(() => {
  
    $('nav .dropdown').hover(function(){
      var $this = $(this);
      // 	 timer;
      // clearTimeout(timer);
      $this.addClass('show');
      $this.find('> a').attr('aria-expanded', true);
      // $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
      $this.find('.dropdown-menu').addClass('show');
    }, function(){
      var $this = $(this);
        // timer;
      // timer = setTimeout(function(){
        $this.removeClass('show');
        $this.find('> a').attr('aria-expanded', false);
        // $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
        $this.find('.dropdown-menu').removeClass('show');
      // }, 100);
    });
  
  
    $('#dropdown04').on('show.bs.dropdown', function () {
      console.log('show');
    });
  
    // scroll
    var scrollWindow = function() {
      $(window).scroll(function(){
        var $w = $(this),
            st = $w.scrollTop(),
            navbar = $('.ftco_navbar'),
            sd = $('.js-scroll-wrap');
  
        if (st > 150) {
          if ( !navbar.hasClass('scrolled') ) {
            navbar.addClass('scrolled');	
          }
        } 
        if (st < 150) {
          if ( navbar.hasClass('scrolled') ) {
            navbar.removeClass('scrolled sleep');
          }
        } 
        if ( st > 350 ) {
          if ( !navbar.hasClass('awake') ) {
            navbar.addClass('awake');	
          }
          
          if(sd.length > 0) {
            sd.addClass('sleep');
          }
        }
        if ( st < 350 ) {
          if ( navbar.hasClass('awake') ) {
            navbar.removeClass('awake');
            navbar.addClass('sleep');
          }
          if(sd.length > 0) {
            sd.removeClass('sleep');
          }
        }
      });
    };
    scrollWindow();
},[]);


  //user icon
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };


  
    //isloggedin
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Check if user is already logged in
    const unsubscribe=firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
    return unsubscribe
  }, []);
  
  //logout
  const handleLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        setIsLoggedIn(false);
        // Redirect to home page or do any necessary clean up
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };


  
//achievement value
const [achievements, setAchievements] = useState([]);
const [counts, setCounts] = useState({});

  useEffect(() => {
    const fetchAchievements = async () => {
      const database = firebase.database();
      const achievementsRef = database.ref('Achievement');

      try {
        const snapshot = await achievementsRef.once('value');
        const data = snapshot.val();
        const achievementsArray = Object.values(data).filter(item => typeof item === 'object');
        setAchievements(achievementsArray);

        const initialCounts = {};
        achievementsArray.forEach(achievement => {
          initialCounts[achievement.Achievement_id] = 0;
        });
        setCounts(initialCounts);

        
        
      } catch (error) {
        console.error('Error fetching achievements:', error);
      }
    };

    fetchAchievements();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounts(prevCounts => {
        const updatedCounts = {};
        achievements.forEach(achievement => {
          const { Achievement_id, Achievement_value } = achievement;
          const increment = 500;
          updatedCounts[Achievement_id] = Math.min(prevCounts[Achievement_id]  + increment, Achievement_value);
        });
        return updatedCounts;
      });
    }, 100); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, [achievements]);


  const [achievementData1, setAchievementData1] = useState(null);

  useEffect(() => {
    const fetchAchievementData = async () => {
      const database = firebase.database();
      const achievementRef = database.ref('Achievement');

      try {
        const snapshot = await achievementRef.once('value');
        const data = snapshot.val();
        setAchievementData1(data);
      } catch (error) {
        console.error('Error fetching achievement data:', error);
      }
    };

    fetchAchievementData();
  }, []);


//testimony


const [testimonialData, setTestimonialData] = useState([]);

useEffect(() => {
  const fetchTestimonialData = async () => {
    const database = firebase.database();
    const testimonialRef = database.ref('Testimony');

    try {
      const snapshot = await testimonialRef.once('value');
      const data = snapshot.val();
      const testimonials = Object.values(data).filter(item => item !== null);

      // Fetch image URLs from Firebase Storage
      const storage = firebase.storage();
      const storageRef = storage.ref();

      const testimonialsWithImages = await Promise.all(
        testimonials.map(async testimonial => {
          const imageUrl = await storageRef.child(`Testimony/person_${testimonial.Testimonial_id}.jpg`).getDownloadURL();
          return { ...testimonial, imageUrl };
        })
      );

      setTestimonialData(testimonialsWithImages);
    } catch (error) {
      console.error('Error fetching testimonial data:', error);
    }
  };

  fetchTestimonialData();
}, []);

 //about content taken in database
 const [aboutContent, setAboutContent] = useState({
  title: '',
  description: '',
  services: []
});

useEffect(() => {
  const fetchAboutContent = async () => {
    try {
      const snapshot = await firebase.database().ref('About').once('value');
      if (snapshot.exists()) {
        const data = snapshot.val();
        const services = Object.values(data).filter(item => typeof item === 'object');
        const { About_title, About_description } = data;

        setAboutContent({
          title: About_title || '',
          description: About_description || '',
          services: services.map(service => ({
            title: service.About_subtitle || '',
            description: service.About_subdescription || ''
          }))
        });
      } else {
        console.error('About section content not found in database');
      }
    } catch (error) {
      console.error('Error fetching about section content:', error);
    }
  };

  fetchAboutContent();
}, []);



//testimony
const [testimonialData1, setTestimonialData1] = useState([]);

useEffect(() => {
  const fetchTestimonialData = async () => {
    const database = firebase.database();
    const testimonialRef = database.ref('Testimony');

    try {
      const snapshot = await testimonialRef.once('value');
      const data = snapshot.val();
      const testimonials = Object.values(data).filter(item => item !== null);

      // Fetch image URLs from Firebase Storage
      const storage = firebase.storage();
      const storageRef = storage.ref();

      const testimonialsWithImages = await Promise.all(
        testimonials.map(async testimonial => {
          const imageUrl = await storageRef.child(`Testimony/person_${testimonial.Testimonial_id}.jpg`).getDownloadURL();
          return { ...testimonial, imageUrl };
        })
      );

      setTestimonialData1(testimonialsWithImages);
    } catch (error) {
      console.error('Error fetching testimonial data:', error);
    }
  };

  fetchTestimonialData();
}, []);




   //appointment form
   const [doctorName, setDoctorName] = useState('');
   const [doctorOptions, setDoctorOptions] = useState([]);
 
   
   useEffect(() => {
    const fetchDoctorOptions = () => {
      const doctorRef = db.ref('doctors_name');
      doctorRef.once('value', (snapshot) => {
        const doctors = snapshot.val();
        if (doctors) {
          const doctorNames = Object.values(doctors);
          setDoctorOptions(doctorNames);
        }
      });
    };
    fetchDoctorOptions();
  }, [db]);

  const [formData, setFormData] = useState({
    Appointmenttab_id: 0, // Initial id value
    Appointmenttab_name: '',
    Appointmenttab_email: '',
    Appointmenttab_date: new Date(),
    Appointmenttab_time: '', // Default time
    Appointmenttab_doctorname: '', 
    Appointmenttab_phone: ''
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [timeOptions1, setTimeOptions1] = useState([]);

  useEffect(() => {
    // Fetch the latest appointment count from the database
    const appointmentsRef = db.ref('Appointment_tab');
    appointmentsRef.once('value', (snapshot) => {
      const appointments = snapshot.val();
      // Get the count of existing appointments
      const count = appointments ? Object.keys(appointments).length : 0;
      setAppointmentCount(count);
    });
  }, []);

  useEffect(() => {
    const fetchTimeOptions = () => {
      if (formData.Appointmenttab_doctorname && formData.Appointmenttab_date) {
        const adminRef = db.ref('adminData');
        adminRef.once('value', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const timeRanges = Object.values(data)
              .filter((item) => item.doctorName === formData.Appointmenttab_doctorname && item.date === formData.Appointmenttab_date.toLocaleDateString('en-US'))
              .map((item) => item.timeRange);
            setTimeOptions1(timeRanges);
          }
        });
      }
    };
    fetchTimeOptions();
  }, [formData.Appointmenttab_doctorname, formData.Appointmenttab_date, db]);
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.toISOString() : '';

    setFormData({ ...formData, Appointmenttab_date: formattedDate });
  };

  
  const handleTimeChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, Appointmenttab_time: value });
  };

  const timeOptions = (() => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(time);
      }
    }
    return options;
  })();

  const handleDoctorNameChange = (e) => {
    const { value } = e.target;
    setDoctorName(value); // Update doctorName state
    setFormData({ ...formData, Appointmenttab_doctorname: value }); // Update formData state
  };
  


  const handleSubmit = (e) => {
    e.preventDefault();
    // Store form data in Realtime Database

    db.ref('Appointment_tab').orderByChild('Appointmenttab_id').limitToLast(1).once('value')
    .then((snapshot) => {
      let latestAppointment = null;
      snapshot.forEach((childSnapshot) => {
        latestAppointment = childSnapshot.val();
      });

      console.log("Latest appointment:", latestAppointment);

      // Calculate the new ID
      const newAppointmentId = latestAppointment ? latestAppointment.Appointmenttab_id + 1 : 1;

      console.log("New appointment ID:", newAppointmentId);
      const updatedFormData = {
        ...formData,
        Appointmenttab_id: newAppointmentId,
        Appointmenttab_doctorname: doctorName, // Add selected doctor's name
      };

      updatedFormData.Appointmenttab_date = updatedFormData.Appointmenttab_date.toISOString();

      return db.ref('Appointment_tab').push(updatedFormData);
    })
      .then(() => {
        console.log('Form data stored in Realtime Database');
        // Clear form data
        setFormData({
          Appointmenttab_id: '',
          Appointmenttab_name: '',
          Appointmenttab_email: '',
          Appointmenttab_date:  new Date(),
          Appointmenttab_time: '',
          Appointmenttab_doctorname: '',
          Appointmenttab_phone: ''
        });
        setSuccessMessage('Appointment successfully scheduled!');
        // Close the modal
        setTimeout(() => {
          setSuccessMessage('');
          setModalVisible(false);
        }, 2000); // Clear the success message after 3 seconds
        
      })
      .catch((error) => {
        console.error('Error storing form data:', error);
      });
   
  };



//paymentpage

const [paymentDetails, setPaymentDetails] = useState({});

const [paymentStatus, setPaymentStatus] = useState('Loading');
const [errorMessage, setErrorMessage] = useState('');
const [bookingDetails, setBookingDetails] = useState(null);
const [showContent, setShowContent] = useState(false);
const [paymentId, setPaymentId] = useState(null);
const [error, setError] = useState(null);
const [bookedBy, setBookedBy] = useState(null);

useEffect(() => {
    // Parse the query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
  
    // Extract parameters and store them in an object
    const params = {};
    for (const [key, value] of urlParams) {
      params[key] = value;
    }
  
    // Set the payment details state
    setPaymentDetails(params);
  }, []);

  
  useEffect(() => { 
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const { displayName, email } = user;
        setBookedBy({ displayName, email });
      } else {
        setBookedBy(null);
      }
    });
    
return () => unsubscribe(); // Cleanup function
}, []); // Run once on mount

  
  useEffect(() => {
    // Fetch payment details from backend
    fetch("/razorpayWebhook")
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch payment details');
        }
        return response.json();
      })
      .then(data => {
        // Set paymentId state with the received paymentId
        setPaymentId(data.paymentId);
      })
      .catch(error => {
        setError(error.message);
      });
  }, []);


  
useEffect(() => {
    // Check if the payment details contain razorpay_payment_link_id
    if (paymentDetails.razorpay_payment_link_id && paymentDetails.razorpay_payment_link_id !== 'N/A') {
      const paymentLinkID = paymentDetails.razorpay_payment_link_id;
      const paymentID = paymentDetails.razorpay_payment_id;
  
      // Update the payment_id field with the latest value in the PaymentResponse database
      firebase.database().ref('PaymentResponse').once('value')
        .then(snapshot => {
          const paymentResponse = snapshot.val();
          if (paymentResponse) {
            for (const key in paymentResponse) {
              if (paymentResponse.hasOwnProperty(key)) {
                const paymentLink = paymentResponse[key].paymentLink;
                if (paymentLink && paymentLink.id === paymentLinkID) {
                  // Update the payment_id field in the database
                  firebase.database().ref(`PaymentResponse/${key}/paymentLink`).update({ payment_id: paymentID })
                    .then(() => {
                      console.log('Payment ID updated in database.');
                    })
                    .catch(error => {
                      console.error('Error updating payment ID:', error);
                    });
                  break; // Exit loop once the matching paymentLink ID is found
                }
              }
            }
          }
        })
        .catch(error => {
          console.error('Error fetching PaymentResponse:', error);
        });
    }
  }, [paymentDetails]);
  

  
useEffect(() => {
    const updatePaymentId = async () => {
      const db = firebase.database();
      const userBookingCardsRef = db.ref('bookingcards');
      
      // Query to get all booking cards sorted by key (timestamp) in descending order
      const allBookingCardsQuery = userBookingCardsRef.orderByKey();
      
      try {
        // Retrieve all booking cards
        const snapshot = await allBookingCardsQuery.once('value');
        let latestBookingCardKey = null;
  
        // Get the key of the latest booking card
        snapshot.forEach(childSnapshot => {
          latestBookingCardKey = childSnapshot.key;
        });
  
        // If there are booking cards, update the payment_id in the latest booking card
        if (latestBookingCardKey) {
          userBookingCardsRef.child(latestBookingCardKey).update({
            payment_id: paymentDetails.razorpay_payment_id,
          });
          console.log('Payment ID updated in the latest booking card successfully');
        } else {
          console.log('No booking cards found');
        }
      } catch (error) {
        console.error('Error updating payment ID:', error);
      }
    };
  
    updatePaymentId();
  }, [paymentDetails]);
  
  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        // Parse the query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        
        // Extract payment ID from query parameters
        const paymentId = urlParams.get('razorpay_payment_id');
  
        if (!paymentId) {
          setPaymentStatus('Payment ID not found');
          return;
        }
  
        // Fetch payment data from Firebase
        const paymentSnapshot = await firebase.database().ref('payments').once('value');
        const payments = paymentSnapshot.val();
  
        // Check if the payment ID exists in the payments data
        if (payments && payments[paymentId]) {
          // Payment ID found, check its status
          const payment = payments[paymentId];
          if (payment.status === 'captured') {
            setPaymentStatus('Payment Successful');
            const bookingSnapshot = await firebase.database().ref('bookingcards').orderByChild('payment_id').equalTo(paymentId).once('value');
            const bookingData = bookingSnapshot.val();
  
            
            if (!bookingData) {
              setErrorMessage('No booking found for this payment ID');
              return;
            }
  
            const bookingId = Object.keys(bookingData)[0];
            const booking = bookingData[bookingId];
  
            await firebase.database().ref(`bookingcards/${bookingId}/Booking_Status`).set('paid');
            
            setBookingDetails(booking);
          } else {
            setPaymentStatus('Payment Failed');
          }
        } else {
          setPaymentStatus('Payment ID not found in database');
        }
      } catch (error) {
        console.error('Error fetching payment status:', error);
        setPaymentStatus('Error fetching payment status');
      }
    };
  
    fetchPaymentStatus();
  }, []);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 10000);
  
    return () => clearTimeout(timer);
  }, []); 
  
    return(
        <>
  <title>DentaCare - Free Bootstrap 4 Template by Colorlib</title>
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700"
    rel="stylesheet"
  />
  <link rel="stylesheet" href="css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="css/animate.css" />
  <link rel="stylesheet" href="css/owl.carousel.min.css" />
  <link rel="stylesheet" href="css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="css/magnific-popup.css" />
  <link rel="stylesheet" href="css/aos.css" />
  <link rel="stylesheet" href="css/ionicons.min.css" />
  <link rel="stylesheet" href="css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="css/jquery.timepicker.css" />
  <link rel="stylesheet" href="css/flaticon.css" />
  <link rel="stylesheet" href="css/icomoon.css" />
  <link rel="stylesheet" href="css/style.css" />
  <nav
    className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
    id="ftco-navbar"
  >
    <div className="container">
      <a className="navbar-brand" href="/">
        {brandName || ''}
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#ftco-nav"
        aria-controls="ftco-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="oi oi-menu" /> Menu
      </button>
      <div className="collapse navbar-collapse" id="ftco-nav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a href="/" className="nav-link">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="/pharmacy" className="nav-link">
              Pharmacy
            </a>
          </li>
          <li className="nav-item active">
            <a href="/about" className="nav-link">
              About
            </a>
          </li>
          <li className="nav-item">
            <a href="/services" className="nav-link">
              Services
            </a>
          </li>
          <li className="nav-item">
            <a href="/doctors" className="nav-link">
              Doctors
            </a>
          </li>
          <li className="nav-item">
            <a href="/contact" className="nav-link">
              Contact
            </a>
          </li>
          <li className="nav-item cta">
            <a
              
              className="nav-link"
              data-toggle="modal"
              data-target="#modalRequest"
              onClick={() => setModalVisible(true)}
            >
              <span>Make an Appointment</span>
            </a>
          </li>
          <div className="quote_btn-container">
      <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <FontAwesomeIcon
          icon={faUser}
          aria-hidden="true"
          style={{ marginRight: '50px', cursor: 'pointer', marginTop: '15px', marginLeft: '15px', color: 'white' }}
        />
        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ backgroundColor: 'white', border: '1px solid black',width: '10', maxHeight: '200px', overflowY: 'auto' }}>
          {isLoggedIn ? (
            <>
              <Link to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }} hover={{backgroundColor:"#007bff"}}>Profile</Link>
              <Link to="/seo-settings" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>SEO Settings</Link>
              <Link to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Admin Page</Link>
              <Link to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Logout</Link>
            </>
          ) : (
            <Link to="/signin" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Login</Link>
          )}
        </div>
      </div>
    </div>
        </ul>
      </div>
    </div>
  </nav>
  {/* END nav */}
  <section className="home-slider ">
    <div
      className="slider-item bread-item"
      style={{ backgroundImage: `url(${bgImg})` }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay" />
      <div className="container" data-scrollax-parent="true">
        <div className="row slider-text align-items-end">
          <div className="col-md-7 col-sm-12 ftco-animate mb-5">
            <p
              className="breadcrumbs"
              data-scrollax=" properties: { translateY: '70%', opacity: 1.6}"
            >
              <span className="mr-2">
                <a href="index.html">Home</a>
              </span>{" "}
              <span>About</span>
            </p>
            <h1
              className="mb-3"
              data-scrollax=" properties: { translateY: '70%', opacity: .9}"
            >
              About Us
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  {!showContent && (
      <>
    <div className="container mt-5">
      <h2 className="mb-4">Payment Details</h2>
      {bookedBy && (
            <>
              <h2 className="mb-4">Booked By</h2>
              <p><strong>Name:</strong> {bookedBy.displayName}</p>
              <p><strong>Email:</strong> {bookedBy.email}</p>
              
            </>
          )}
      {/*<div className="row">
        <div className="col-md-6">
     {paymentInfo && (
        <div>
          <h2>Payment Details</h2>
          <p>Name: {paymentInfo.name}</p>
          <p>Email: {paymentInfo.email}</p>
          <p>Contact: {paymentInfo.contact}</p>
          <p>Status: {paymentInfo.status}</p>
          <p>ID: {paymentInfo.id}</p>
        </div>
      )}
    </div>
        
      </div>*/}
    </div><br></br><br></br>
    <div>
      <h1>Payment Details</h1>
      <ul>
        <li>Razorpay Payment ID: {paymentDetails.razorpay_payment_id}</li>
        <li>Razorpay Payment Link ID: {paymentDetails.razorpay_payment_link_id}</li>
        <li>Razorpay Payment Link Status: {paymentDetails.razorpay_payment_link_status}</li>
      </ul>
    </div><br></br><br></br><br></br>
    </>
    )}
    {showContent && (
  <>
    <div className="container mt-5 py-5 bg-light">
      <div className="card">
        <h2 className="card-header bg-primary text-white border-0">{paymentStatus}</h2>
        <div className="card-body">
          {errorMessage && <p className="card-text mb-3 text-danger">{errorMessage}</p>}
          {bookingDetails && (
            <div>
              <h2 className="card-title mb-4">Booked By</h2>
              <p className="card-text mb-3">Name: {bookingDetails.user_name}</p>
              <p className="card-text mb-3">Email: {bookingDetails.user_email}</p>
              <h2 className="card-title mb-4">Booking Details</h2>
              <p className="card-text mb-3">Payment ID: {bookingDetails.payment_id}</p>
              <p className="card-text mb-3">Price: {bookingDetails.Booking_Price}</p>
              <p className="card-text mb-3">Payment Date: {bookingDetails.booking_DateTime}</p>
             
              
            </div>
          )}
        </div>
      </div>
    </div>
  </>
)}
<br></br><br></br><br></br>
  
  <footer className="ftco-footer ftco-bg-dark ftco-section">
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-3">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">DentaCare.</h2>
            <p>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts.
            </p>
          </div>
          <ul className="ftco-footer-social list-unstyled float-md-left float-lft ">
            <li className="ftco-animate">
              <a href="#">
                <span className="icon-twitter" />
              </a>
            </li>
            <li className="ftco-animate">
              <a href="#">
                <span className="icon-facebook" />
              </a>
            </li>
            <li className="ftco-animate">
              <a href="#">
                <span className="icon-instagram" />
              </a>
            </li>
          </ul>
        </div>
        <div className="col-md-2">
          <div className="ftco-footer-widget mb-4 ml-md-5">
            <h2 className="ftco-heading-2">Quick Links</h2>
            <ul className="list-unstyled">
              <li>
                <a href="#" className="py-2 d-block">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Features
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Projects
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Blog
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="col-md-3">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Office</h2>
            <div className="block-23 mb-3">
              <ul>
                <li>
                  <span className="icon icon-map-marker" />
                  <span className="text">
                    {officeInfo.address}
                  </span>
                </li>
                <li>
                  <a href="#">
                    <span className="icon icon-phone" />
                    <span className="text">{officeInfo.phoneNumber}</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="icon icon-envelope" />
                    <span className="text">{officeInfo.email}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <p>
            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            Copyright © All rights reserved | This template is made 
            <i className="icon-heart" aria-hidden="true" /> by{" "}
            <a href="https://www.datadna.in/" target="_blank">
              DATADNA
            </a>
            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
          </p>
        </div>
      </div>
    </div>
  </footer>
  {/* loader */}

  {/* Modal */}
  {modalVisible && (
    <div className="modal" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="modalRequestLabel">Make an Appointment</h5>
        <button type="button" className="close" onClick={() => setModalVisible(false)}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" className="form-control" name="Appointmenttab_name" value={formData.Appointmenttab_name} onChange={handleInputChange} placeholder="Full Name" />
          </div>
          <div className="form-group">
            <input type="email" className="form-control" name="Appointmenttab_email" value={formData.Appointmenttab_email} onChange={handleInputChange} placeholder="Email" />
          </div>
          <div className="form-group">
            <input type="tel" className="form-control" name="Appointmenttab_phone" value={formData.Appointmenttab_phone} onChange={handleInputChange} placeholder="Phone Number" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  selected={formData.Appointmenttab_date}
                  onChange={(date) => setFormData({ ...formData, Appointmenttab_date: date })}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  placeholderText="Select Date"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select className="form-control" name="Appointmenttab_time" value={formData.Appointmenttab_time} onChange={handleTimeChange} placeholder="Select Time">
                  <option value="">Select Time</option>
                  {timeOptions1.map((time, index) => (
                    <option key={index} value={time}>{time}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                      name="Appointmenttab_doctorname" value={formData.Appointmenttab_doctorname} onChange={handleDoctorNameChange} placeholder="Select Doctor"
                      >
                      {doctorOptions.map((doctor, index) => (
                        <option key={index} style={{ color: 'black' }}>{doctor}</option>
                      ))}
                </select>
                </div>
              </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">Make an Appointment</button>
          </div>
          {successMessage && <p className="text-success">{successMessage}</p>}
        </form>
      </div>
    </div>
  </div>
</div>

  )}

  
</>

    );
}
export default PaymentPage;