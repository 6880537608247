import React from 'react';
import { FaWhatsapp, FaPhone, FaArrowUp } from 'react-icons/fa';
import './FloatingIcons.css';

const FloatingIcons = ({ isFooter }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className={`floating-icons ${isFooter ? 'footer-icons' : ''}`}>
     <a
        href="#"
        className="floating-iconup top"
        onClick={scrollToTop}
        style={isFooter ? { color: 'black', margin: '0 10px' } : {}}
      >
        <FaArrowUp />
      </a>
      <a
        href="https://wa.link/f587xt"
        className="floating-icon whatsapp"
        target="_blank"
        rel="noopener noreferrer"
        style={isFooter ? { color: 'black', margin: '0 10px' } : {}}
      >
        <FaWhatsapp />
      </a>
      <a
        href="tel:+91 7447447306"
        className="floating-icon phone"
        style={isFooter ? { color: 'black', margin: '0 10px' } : {}}
      >
        <FaPhone />
      </a>
     
    </div>
  );
};

export default FloatingIcons;
