import React,{useEffect, useState} from 'react';
import bgImg from './images/physiotherapy.jpg';
import image1Img from './images/image_1.jpg';
import image2Img from './images/image_2.jpg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage'
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaTrash } from 'react-icons/fa';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import './adminpage.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { IoLocationSharp, IoCallSharp, IoMailSharp, IoMapSharp, IoMedicalSharp } from 'react-icons/io5';
import { FaBars } from 'react-icons/fa';
import webicon from './images/websiteicon.png';
import FloatingIcons from './FloatingIcons';
import { faClock } from "@fortawesome/free-solid-svg-icons";
import Loader from './Loader';

const AdminPage=()=>{

  



  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };
  
  //user icon
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  
    //isloggedin
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Check if user is already logged in
    const unsubscribe=firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
    return unsubscribe
  }, []);
  
  //logout
  const handleLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        setIsLoggedIn(false);
        // Redirect to home page or do any necessary clean up
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };
  
  


  const [brandName, setBrandName] = useState('');

  useEffect(() => {
    const fetchBrandName = async () => {
      try {
        const snapshot = await firebase.database().ref('Title/Clinic').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setBrandName(data);
        } else {
          console.error('Brand name not found in database');
        }
      } catch (error) {
        console.error('Error fetching brand name:', error);
      }
    };

    fetchBrandName();
  }, []);


  const [medicines, setMedicines] = useState([]);

  useEffect(() => {
    // Fetch data from Firebase Realtime Database
    const fetchData = async () => {
      const db = firebase.database();
      const ref = db.ref("Pharmacy_Collection");
      ref.on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert object to array
          const medicineArray = Object.values(data).filter(Boolean);
          setMedicines(medicineArray);
        }
      });
    };

    fetchData();

    // Clean up listener on unmount
    return () => {
      const db = firebase.database();
      const ref = db.ref("Pharmacy_Collection");
      ref.off("value");
    };
  }, []);

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const storage = firebase.storage();

      const urls = await Promise.all(medicines.map(async (medicine) => {
        try {
          const url = await storage.ref().child(`Pharmacy_collection/image (${medicine.Medicine_id}).png`).getDownloadURL();
          return { id: medicine.Medicine_id, url };
        } catch (error) {
          console.error('Error fetching image:', error);
          return null;
        }
      }));

      setImageUrls(urls.filter(entry => entry !== null));
    };

    fetchImageUrls();
  }, [medicines]);

  
  useEffect(() => {
  
    $('nav .dropdown').hover(function(){
      var $this = $(this);
      // 	 timer;
      // clearTimeout(timer);
      $this.addClass('show');
      $this.find('> a').attr('aria-expanded', true);
      // $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
      $this.find('.dropdown-menu').addClass('show');
    }, function(){
      var $this = $(this);
        // timer;
      // timer = setTimeout(function(){
        $this.removeClass('show');
        $this.find('> a').attr('aria-expanded', false);
        // $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
        $this.find('.dropdown-menu').removeClass('show');
      // }, 100);
    });
  
  
    $('#dropdown04').on('show.bs.dropdown', function () {
      console.log('show');
    });
  
    // scroll
    var scrollWindow = function() {
      $(window).scroll(function(){
        var $w = $(this),
            st = $w.scrollTop(),
            navbar = $('.ftco_navbar'),
            sd = $('.js-scroll-wrap');
  
        if (st > 150) {
          if ( !navbar.hasClass('scrolled') ) {
            navbar.addClass('scrolled');	
          }
        } 
        if (st < 150) {
          if ( navbar.hasClass('scrolled') ) {
            navbar.removeClass('scrolled sleep');
          }
        } 
        if ( st > 350 ) {
          if ( !navbar.hasClass('awake') ) {
            navbar.addClass('awake');	
          }
          
          if(sd.length > 0) {
            sd.addClass('sleep');
          }
        }
        if ( st < 350 ) {
          if ( navbar.hasClass('awake') ) {
            navbar.removeClass('awake');
            navbar.addClass('sleep');
          }
          if(sd.length > 0) {
            sd.removeClass('sleep');
          }
        }
      });
    };
    scrollWindow();
},[]);




const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [email, setEmail] = useState('');
const [age, setAge] = useState('');
const [phoneNumber, setPhoneNumber] = useState('');
const [gender, setGender] = useState('');
const [location, setLocation] = useState('');
const [message, setMessage] = useState('');

useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
        const userId = currentUser.uid;
        const userRef = firebase.database().ref('users/' + userId);

        // Fetch user data from the Realtime Database
        userRef.once('value', (snapshot) => {
            const userData = snapshot.val();
            if (userData) {
                setFirstName(userData.firstName || '');
                setLastName(userData.lastName || '');
                setEmail(userData.email || '');
                setAge(userData.age || '');
                setPhoneNumber(userData.phoneNumber || '');
                setGender(userData.gender || '');
                setLocation(userData.location || '');
            } else {
                setMessage('User data not found');
            }
        }, (error) => {
            console.error('Error fetching user data:', error);
            setMessage('Error fetching user data');
        });
    } else {
        setMessage('User not authenticated');
    }
}, []);
useEffect(() => {
  // Store form data in localStorage
  localStorage.setItem('profileFormData', JSON.stringify({
    firstName,
    lastName,
    email,
    age,
    phoneNumber,
    gender,
    location
  }));
}, [firstName, lastName, email, age, phoneNumber, gender, location]);

const handleUpdateProfile = (e) => {
  e.preventDefault();
  const userId = firebase.auth().currentUser.uid;
  const userRef = firebase.database().ref('users/' + userId);

  userRef.update({
    firstName,
    lastName,
    email: email,
    age,
    phoneNumber,
    gender,
    location
  }).then(() => {
    setMessage('Profile updated successfully');
  }).catch((error) => {
    console.error('Error updating profile:', error);
    setMessage('Error updating profile');
  });
};

//admin
    const [startDate, setStartDate] = useState(new Date());
    const [doctorOptions, setDoctorOptions] = useState([]);
    const today = new Date();
    const todayString = `${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}-${today.getFullYear()}`;
    
    
  const [doctorName, setDoctorName] = useState('');
  const [fromHour, setFromHour] = useState(0);
  const [fromMinute, setFromMinute] = useState(0);
  const [toHour, setToHour] = useState(0);
  const [toMinute, setToMinute] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState([]);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [doctorOptions1, setDoctorOptions1] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  
  useEffect(() => {
    // Fetch doctor options from Firebase
    const doctorRef = firebase.database().ref('doctors_name');
    doctorRef.once('value', (snapshot) => {
      const doctors = snapshot.val();
      if (doctors) {
        const doctorNames = Object.values(doctors);
        setDoctorOptions(doctorNames);

        if (doctorNames.length > 0) {
          setDoctorName(doctorNames[0]);
        }
      }
    });
  }, []);

  useEffect(() => {
    // Fetch doctor options from Firebase
    const doctorRef = firebase.database().ref('doctors_name');
    doctorRef.once('value', (snapshot) => {
      const doctors = snapshot.val();
      if (doctors) {
        const doctorNames = Object.values(doctors);
        setDoctorOptions1(doctorNames);

        if (doctorNames.length > 0) {
          setDoctorName(doctorNames[0]);
        }
      }
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

      
    // Format the time values
    const fromTime = `${fromHour.toString().padStart(2, '0')}:${fromMinute.toString().padStart(2, '0')}`;
    const toTime = `${toHour.toString().padStart(2, '0')}:${toMinute.toString().padStart(2, '0')}`;

    // Combine from and to time
    const timeRange = `From ${fromTime} to ${toTime}`;

    // Create a data object with the extracted values
    const adminData = {
      doctorName: doctorName,
      date: startDate.toLocaleDateString(),
      timeRange: timeRange
    };

    // Push the data object to Firebase Realtime Database
    firebase.database().ref('adminData').push(adminData)
      .then(() => {
        console.log('Admin data added to Firebase');
        setSuccessMessage('Submitted successfully!');
        setDoctorName('Choose your doctor');
        setStartDate(new Date());
        setFromHour(0);
        setFromMinute(0);
        setToHour(0);
        setToMinute(0);
        window.location.href = '/adminpage';
      })
      .catch(error => {
        console.error('Error adding admin data to Firebase: ', error);
        // You can add further actions here, like showing an error message to the user
      });
  };


  const handleDelete = (doctorName, date, timeRange) => {
    // Remove the corresponding data from Firebase Realtime Database
    firebase.database().ref('adminData').orderByChild('doctorName').equalTo(doctorName)
      .once('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          const childData = childSnapshot.val();
        if (childData.date === date && childData.timeRange === timeRange) {
            childSnapshot.ref.remove()
              .then(() => {
                console.log('Data deleted from Firebase');
                window.location.href = '/adminpage';
              })
              .catch(error => {
                console.error('Error deleting data from Firebase: ', error);
              });
          }
        });
      });
  };

  
  //handle delete appointment fields
  const handleDeleteAppointment = (date, patientName) => {
    // Remove the corresponding appointment data from Firebase Realtime Database
    firebase.database().ref('Appointment_tab')
      .orderByChild('Appointmenttab_date').equalTo(date)
      .once('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          if (childSnapshot.val().Appointmenttab_name === patientName) {
            childSnapshot.ref.remove()
              .then(() => {
                console.log('Appointment data deleted from Firebase');
                // Optionally, you can update the state or perform any additional actions after deletion
                window.location.href = '/adminpage';
              })
              .catch(error => {
                console.error('Error deleting appointment data from Firebase: ', error);
              });
          }
        });
      });
  };

  useEffect(() => {
    const filterAppointments = () => {
      let filtered = [...appointmentsData];

      if (fromDate) {
        filtered = filtered.filter((appointment) => {
          const appointmentDate = new Date(appointment.Appointmenttab_date);
          return !isNaN(appointmentDate) && appointmentDate >= new Date(fromDate);
        });
      }

      if (toDate) {
        filtered = filtered.filter((appointment) => {
          const appointmentDate = new Date(appointment.Appointmenttab_date);
          return !isNaN(appointmentDate) && appointmentDate <= new Date(toDate);
        });
      }

      filtered.sort((a, b) => new Date(a.Appointmenttab_date) - new Date(b.Appointmenttab_date));

      setFilteredAppointments(filtered);
    };

    filterAppointments();
  }, [fromDate, toDate, appointmentsData]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    if (!isNaN(date)) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return isoString; // Return the original string if invalid date
  };

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = firebase.database().ref('adminData');
      dbRef.once('value', (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const dataArray = Object.values(data);
          setData(dataArray);
        }else{
            setData([]);
        }
      });
    };

    fetchData();

    return () => {
        firebase.database().ref('adminData').off(); 
    };
  }, []);


 
  useEffect(() => {
    const fetchAppointments = () => {
      const appointmentsRef = firebase.database().ref('Appointment_tab');
      appointmentsRef.once('value', (snapshot) => {
        const appointments = snapshot.val();
        if (appointments) {
          const appointmentsArray = Object.values(appointments);
          setAppointmentsData(appointmentsArray);
        } else {
          setAppointmentsData([]);
        }
      });
    };
    fetchAppointments();
    return () => {
      firebase.database().ref('Appointment_tab').off();
    };
  }, []);


  
  //footer address change
  const [officeInfo, setOfficeInfo] = useState({
    email: '',
    phoneNumber: '',
    address: ''
  });

  
useEffect(()=>{
  document.title="Admin | Active Care Physiotherapy & Sports Injury Clinic"
},[]);

  useEffect(() => {
    const fetchOfficeInfo = async () => {
      try {
        const snapshot = await firebase.database().ref('Address').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setOfficeInfo({
            email: data.Address_email || '',
            phoneNumber: data.Address_number || '',
            phoneNumber1: data.Address_number1 || '',
            address: data.Address_place || ''
          });
        } else {
          console.error('Office information not found in database');
        }
      } catch (error) {
        console.error('Error fetching office information:', error);
      }
    };

    fetchOfficeInfo();
  }, []);


  const handleButtonClick = async () => {
    const dateRange = document.getElementById('date-range').value;
    const star4 = document.getElementById('4star').checked;
    const star5 = document.getElementById('5star').checked;
    const minWords = document.getElementById('min-words').value;

    const filters = {
      dateRange,
      star4,
      star5,
      minWords
    };

    try {
      const response = await fetch('https://us-central1-activecare-8b7d9.cloudfunctions.net/api/fetch-reviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(filters)
      });
      const data = await response.json();
      console.log(data); // This will log the reviews fetched from Google API

      const successMessage = document.getElementById('success-message');
      successMessage.style.display = 'block';
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };



    return(
        <>
  <title>DentaCare - Free Bootstrap 4 Template by Colorlib</title>
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700"
    rel="stylesheet"
  />
  <link rel="stylesheet" href="css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="css/animate.css" />
  <link rel="stylesheet" href="css/owl.carousel.min.css" />
  <link rel="stylesheet" href="css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="css/magnific-popup.css" />
  <link rel="stylesheet" href="css/aos.css" />
  <link rel="stylesheet" href="css/ionicons.min.css" />
  <link rel="stylesheet" href="css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="css/jquery.timepicker.css" />
  <link rel="stylesheet" href="css/flaticon.css" />
  <link rel="stylesheet" href="css/icomoon.css" />
  <link rel="stylesheet" href="css/style.css" />
  <header>
  <nav
    className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
    id="ftco-navbar"
  >
    <div className="container">
    <img className="icoimage" src={webicon} alt="Website Icon" style={{ width: '80px' }} />
    <div className='column'>
    <a className="navbar-brand" href="/" style={{color:"black"}}>
      
      <span className="desktop-view">
      ACTIVECARE PHYSIOTHERAPY & SPORTS INJURY CLINIC
      <br />
      <span className="slogan">Your Pain Stops Here</span>
    </span>
     <span className="mobile-view">
      ActiveCare Physiotherapy<br />&<br />Sports Injury Clinic
      <br />
      <span className="slogan">Your Pain Stops Here</span>
    </span>
</a>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#ftco-nav"
        aria-controls="ftco-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <FaBars />
      </button>
      <div className="collapse navbar-collapse" id="ftco-nav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a href="/" className="nav-link" style={{color:"black"}}>
              Home
            </a>
          </li>
          {/*<li className="nav-item active">
            <a href="/pharmacy" className="nav-link">
              Pharmacy
            </a>
          </li>*/}
          <li className="nav-item">
            <a href="/about" className="nav-link" style={{color:"black"}}>
              About
            </a>
          </li>
          <li className="nav-item ">
            <a href="/services" className="nav-link" style={{color:"black"}}>
              Services
            </a>
          </li>
          <li className="nav-item">
            <a href="/doctors" className="nav-link" style={{color:"black"}}>
              Doctors
            </a>
          </li>
          <li className="nav-item">
            <a href="/contact" className="nav-link" style={{color:"black"}}>
              Contact
            </a>
          </li>
          
          
          <div className="quote_btn-container">
      <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <FontAwesomeIcon
          icon={faUser}
          aria-hidden="true"
          style={{ marginRight: '50px', cursor: 'pointer', marginTop: '15px', marginLeft: '15px', color: '#EA8D8D' }}
        />
        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ backgroundColor: 'white', border: '1px solid black',width: '10', maxHeight: '200px', overflowY: 'auto' }}>
          {isLoggedIn ? (
            <>
              <Link to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }} hover={{backgroundColor:"#007bff"}}>Profile</Link>
              <Link to="/seo-settings" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>SEO Settings</Link>
              <Link to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Admin Page</Link>
              <Link to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Logout</Link>
            </>
          ) : (
            <Link to="/signin" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Login</Link>
          )}
        </div>
      </div>
    </div>
        </ul>
      </div>
    </div>
  </nav>
  </header>
  <Loader/>
  {/* END nav */}
  <section className=" home-slider">
    <div
      className="slider-item bread-item"
      style={{ backgroundImage: `url(${bgImg})` }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay" />
      <div className="container" data-scrollax-parent="true">
        <div className="row slider-text align-items-end">
          <div className="col-md-7 col-sm-12 ftco-animate mb-5">
            <p
              className="breadcrumbs"
              data-scrollax=" properties: { translateY: '70%', opacity: 1.6}"
            >
              <span className="mr-2">
                <a href="/" style={{color:"white"}}>Home</a>
              </span>{" "}
              <span style={{color:"#301934"}}>Admin Page</span>
            </p>
            <h1
              className="mb-3"
              data-scrollax=" properties: { translateY: '70%', opacity: .9}"
            >
              Admin Update
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="App">
      
    </div>
  <section className="ftco-section">
    <div className="container">
      <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
        <h2 className="mb-2">Trigger Review</h2>
        <select id="date-range" className="mb-3">
          <option value="1">Last one month</option>
          <option value="3">Last three months</option>
          <option value="6">Last six months</option>
          <option value="12">One year</option>
          <option value="24">Two years</option>
        </select><br></br>
        <label><input type="checkbox" id="4star" value="4"/> 4 Star</label>
        <label><input type="checkbox" id="5star" value="5"/> 5 Star</label><br></br>
        <select id="min-words" className="mb-3">
          <option value="20">Minimum 20 words</option>
          <option value="30">Minimum 30 words</option>
          <option value="40">Minimum 40 words</option>
          <option value="50">Minimum 50 words</option>
        </select><br></br>
      
        <button className='styled-button' onClick={handleButtonClick}>Fetch and Store Reviews</button>
        <div id="success-message" className="success-message" style={{display: "none", color: "green"}}>Reviews fetched and stored successfully!</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={handleSubmit} className="admin_form">
            <h4>
              Book <span>Appointment</span>
            </h4>
            <div className="form-row ">
            {doctorOptions.length > 0 && (
              <div className="form-group col-lg-4">
                <label htmlFor="inputDoctorName">Doctor Name</label>
                <select
                  name=""
                  className="form-control wide"
                  id="inputDoctorName"
                  value={doctorName}
                  onChange={(e) => setDoctorName(e.target.value)}
                >
                  {doctorOptions.map((doctor, index) => (
                    <option key={index}>{doctor}</option>
                  ))}
                </select>
              </div>
            )}
            </div>
            <div className='form-row'>
            <div className="form-group col-lg-4">
                <label htmlFor="inputDate">Choose Date </label>
                <div className="input-group date">
                <DatePicker
                  id="inputDate"
                  className="form-control"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                />
                </div>
              </div>
              </div>
              <div className="form-row ">
                <div className="form-group col-lg-2">
                    <label htmlFor="fromTime">From</label>
                    <div className="input-group date">
                        <select className="form-control" id="fromHour"  value={fromHour}
                      onChange={(e) => setFromHour(e.target.value)}>
                            {[...Array(24)].map((_, hour) => (
                            <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                            ))}
                        </select>
                        <span className="input-group-text">:</span>
                        <select className="form-control" id="fromMinute" value={fromMinute}
                      onChange={(e) => setFromMinute(e.target.value)}>
                            {[...Array(60)].map((_, minute) => (
                            <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                        ))}
                        </select>
                    </div>
                </div>
             <div className="form-group col-lg-2">
                <label htmlFor="toTime">To</label>
                <div className="input-group date">
                    <select className="form-control" id="toHour" value={toHour}
                      onChange={(e) => setToHour(e.target.value)}>
                        {[...Array(24)].map((_, hour) => (
                        <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                        ))}
                    </select>
                    <span className="input-group-text">:</span>
                    <select className="form-control" id="toMinute"   value={toMinute}
                      onChange={(e) => setToMinute(e.target.value)}>
                        {[...Array(60)].map((_, minute) => (
                        <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                        ))}
                    </select>
                </div>
            </div>
            
            </div>
            <div className="btn-box">
              <button type="submit" className="btn " style={{color:"white",backgroundColor:"blue",borderColor:"blue"}}>
                Submit
              </button>
            </div>
            {successMessage && <p>{successMessage}</p>}
         
          </form>
        </div>
      </div>
      </div>
  </section>
  
  <section className="admin_table_section layout_padding-bottom">
    <div className="container">
   
    <div className="table-container"></div>
    <h2>Appointment Table</h2>
    <div className="mb-3">
    <PDFDownloadLink
      document={<PDFDocument data={data} />}
        fileName="appointment_table.pdf"
        >
        {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Export in pdf'
        }
    </PDFDownloadLink>
    </div>
    <table id="adminTable">
   
        <thead>
          <tr>
            <th style={{color:"black"}}>Doctor Name</th>
            <th style={{color:"black"}}>Date</th>
            <th style={{color:"black"}}>Time</th>
            <th style={{color:"black"}}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.doctorName}</td>
              <td>{format(new Date(item.date), 'dd/MM/yyyy')}</td>
              <td>{item.timeRange}</td>
              <td><FaTrash onClick={(e) =>{e.preventDefault(); handleDelete(item.doctorName, item.date, item.timeRange);}}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table><br></br>
    </div>
  </section>

  <section className="appointments_table_section layout_padding-bottom">
      <div className="container">
        <div className="table-container"></div>
        <h2>Patient Appointments</h2>
        <div className="mb-3">
          <PDFDownloadLink
            document={<PDFDocument1 appointmentsData={filteredAppointments} />}
            fileName="patient_appointments.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : 'Export in pdf'
            }
          </PDFDownloadLink>
        </div>
        <div className="date-filters">
          <div className="filter">
            <label>From:</label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="filter">
            <label>To:</label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="filter">
            <label>Select Doctor:</label>
            <select
              value={selectedDoctor}
              onChange={(e) => setSelectedDoctor(e.target.value)}
            >
              
              {doctorOptions1.map((doctor, index) => (
                <option key={index}>{doctor}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-responsive">
          <table id="appointmentsTable">
            <thead>
              <tr>
                <th style={{ color: 'black' }}>Date</th>
                <th style={{ color: 'black' }}>Time</th>
                <th style={{ color: 'black' }}>Name</th>
                <th style={{ color: 'black' }}>Email</th>
                <th style={{ color: 'black' }}>Doctor Name</th>
               
                <th style={{ color: 'black' }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredAppointments.map((item, index) => (
                <tr key={index}>
                  <td>{formatDate(item.Appointmenttab_date)}</td>
                  <td>{item.Appointmenttab_time}</td>
                  <td>{item.Appointmenttab_name}</td>
                  <td>{item.Appointmenttab_email}</td>
                  <td>{item.Appointmenttab_doctorname}</td>
                
                  <td>
                    <FaTrash
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteAppointment(item.Appointmenttab_date, item.Appointmenttab_name);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <footer className="ftco-footer ftco-bg-dark ftco-section footer">
      <div className="container" style={{marginTop:"20px"}}>
        <div className="row mb-5">
          <div className="col-md-4">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2" style={{fontWeight:"bold"}}>{brandName || ''}</h2>
              <p style={{color:"black"}}>
                A fully equipped center for effective physiotherapy treatments. Our experienced and clinically versatile physiotherapist are immensely knowledgeable in clinical reasoning and hypothesizing the clinical impressions.
              </p>
            </div>
            <svg className="svg--source" width={0} height={0} aria-hidden="true">
           
            <symbol id="svg--twitter" viewBox="0 -7 15 30">
      <path d="M15.36 3.434c-0.542 0.241-1.124 0.402-1.735 0.476 0.624-0.374 1.103-0.966 1.328-1.67-0.583 0.346-1.23 0.598-1.917 0.733-0.551-0.587-1.336-0.954-2.205-0.954-1.668 0-3.020 1.352-3.020 3.019 0 0.237 0.026 0.467 0.078 0.688-2.51-0.126-4.735-1.328-6.224-3.155-0.261 0.446-0.41 0.965-0.41 1.518 0 1.048 0.534 1.972 1.344 2.514-0.495-0.016-0.961-0.151-1.368-0.378 0 0.013 0 0.025 0 0.038 0 1.463 1.042 2.683 2.422 2.962-0.253 0.069-0.52 0.106-0.796 0.106-0.194 0-0.383-0.018-0.568-0.054 0.384 1.2 1.5 2.073 2.821 2.097-1.034 0.81-2.335 1.293-3.75 1.293-0.244 0-0.484-0.014-0.72-0.042 1.336 0.857 2.923 1.357 4.63 1.357 5.554 0 8.592-4.602 8.592-8.593 0-0.13-0.002-0.261-0.009-0.39 0.59-0.426 1.102-0.958 1.507-1.563z" />
    </symbol>
           
            <symbol id="svg--google" viewBox="-13 -13 72 72">
              <path
                d="M48,22h-5v-5h-4v5h-5v4h5v5h4v-5h5 M16,21v6.24h8.72c-0.67,3.76-3.93,6.5-8.72,6.5c-5.28,0-9.57-4.47-9.57-9.75
        s4.29-9.74,9.57-9.74c2.38,0,4.51,0.82,6.19,2.42v0.01l4.51-4.51C23.93,9.59,20.32,8,16,8C7.16,8,0,15.16,0,24s7.16,16,16,16
        c9.24,0,15.36-6.5,15.36-15.64c0-1.17-0.11-2.29-0.31-3.36C31.05,21,16,21,16,21z"
              />
            </symbol>
            <symbol id="svg--facebook" viewBox="0 -7 16 30">
              <path d="M12 3.303h-2.285c-0.27 0-0.572 0.355-0.572 0.831v1.65h2.857v2.352h-2.857v7.064h-2.698v-7.063h-2.446v-2.353h2.446v-1.384c0-1.985 1.378-3.6 3.269-3.6h2.286v2.503z" />
            </symbol>
         
            
            <symbol id="svg--youtube" viewBox="-150 -150 800 800">
              <path
                d="M459,61.2C443.7,56.1,349.35,51,255,51c-94.35,0-188.7,5.1-204,10.2C10.2,73.95,0,163.2,0,255s10.2,181.05,51,193.8
              C66.3,453.9,160.65,459,255,459c94.35,0,188.7-5.1,204-10.2c40.8-12.75,51-102,51-193.8S499.8,73.95,459,61.2z M204,369.75v-229.5
              L357,255L204,369.75z"
              />
            </symbol>
          </svg>
          <div className="wrapper">
            <div className="connect">
              <a href="https://www.instagram.com/activecarephysiotherapy_omr/" className="share twitter">
              <i className="fab fa-instagram" style={{width:"30px",height:"10px",fontWeight:"bold",fontSize:"30px",marginTop:"7px", marginLeft:"10px"}}></i>

                <span className="clip">TWITTER</span>
              </a>
              <a href="https://maps.app.goo.gl/1sWcVQPMfMHd8cHf7" rel="author" className="share google">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--google" />
                </svg>
                <span className="clip">GOOGLE +</span>
              </a>
              <a href="https://www.facebook.com/profile.php?id=61562412287941" rel="author" className="share facebook">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--facebook" />
                </svg>
                <span className="clip">FACEBOOK</span>
              </a>
            
              <a href="" className="share  youtube">
                <svg role="presentation" className="svg--icon">
                  <use xlinkHref="#svg--youtube" />
                </svg>
                <span className="clip">YOU-TUBE</span>
              </a>
            </div>
          </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center justify-content-md-start">
            <div className="ftco-footer-widget1 footer-duplicate mb-4">
              <h2 className="ftco-heading-2 mb-4" style={{fontWeight:"bold"}}>Address</h2>
              <div className="block-23 mb-4">
                <div className="row">
                  <div className="col-md-6">
                    <ul>
                      <li>
                        <span  style={{ color: "black" }}>
                         
                          {brandName}
                        </span>
                      </li>
                      <li>
                        <span className="text" style={{ color: "black" }}>
                          <IoLocationSharp style={{ marginRight: '8px' }} />
                          {officeInfo.address}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li>
                        <a className="text-center text-md-left">
                          <IoCallSharp style={{ marginRight: '8px' }} />
                          <span className="text" style={{ color: "black" }}>{officeInfo.phoneNumber},{officeInfo.phoneNumber1}</span>
                        </a>
                      </li>
                      <li>
                        <a className="text-center text-md-left">
                          <IoMailSharp style={{ marginRight: '8px' }} />
                          <span className="text" style={{ color: "black" }}>{officeInfo.email}</span>
                        </a>
                      </li>
                      <li>
                        <span className='text'>
                          <IoMapSharp style={{ marginRight: '8px', color: "black" }} />
                          <a href="https://maps.app.goo.gl/CPHkq33Ryk955HJ5A" style={{ color: "black" }}>Google Map</a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
            
              <div className="col-md-3 p-4 color-2card ftco-intro container2 " style={{background:"#8c6dfc", borderRadius:"10px"}}>
                <h3 className="mb-4"><FontAwesomeIcon icon={faClock} /> Opening Hours</h3>
                <hr></hr>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Monday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Tuesday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Wednesday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Thursday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Friday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Saturday</span>
                  <span className="time">10 AM - 1 PM, 4 PM - 8 PM</span>
                </p>
                <p className="openinghours d-flex justify-content-between">
                  <span className="day">Sunday</span>
                  <span className="time">By Appointment</span>
                </p>
              </div>
            </div>
            
        
      
         <hr style={{color:"black"}}></hr>
          <div className="quick-links">
          <div className='footerq'>
          <p className="pfooter " style={{ color: "black",marginTop:"15px"}}>
            Designed & Developed by
            <a href="https://www.datadna.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#023E8A", marginLeft: '5px' }}>
              DATADNA
            </a>
          </p>
          </div>
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/services">Services</a>
            <a href="/doctors">Doctors</a>
            <a href="/contact">Contact</a>
          </div>
        </div>
        <div className="footer-icons-wrapper">
          <FloatingIcons isFooter={true} />
        </div>
      
    </footer>
</>

    );
}


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomColor: '#AAAAAA',
    backgroundColor: '#BAB86C',
    textAlign: 'center',
    padding: 5,
    fontSize: 15
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomColor: '#AAAAAA',
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    padding: 5,
    fontSize: 8
  },
  '@media (max-width: 768px)': {
    tableCol: {
      width: '25%', // Adjust the width for smaller screens
      fontSize: 5 // Increase font size for better readability
    }
  }
});

const PDFDocument = ({ data }) => (
  <Document>
      <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Doctor Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Time</Text>
            </View>
          </View>
          {data.map((item, index) => (
            <View key={index} style={index % 2 === 0 ? { ...styles.tableRow, backgroundColor: '#DDDDDD' } : styles.tableRow}>
              <View style={styles.tableCol}>
                <Text>{item.doctorName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{format(new Date(item.date), 'dd/MM/yyyy')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.timeRange}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const PDFDocument1 = ({ appointmentsData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Time</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Patient Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Email</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Doctor Name</Text>
            </View>
          </View>
          {appointmentsData.map((item, index) => (
            <View
              key={index}
              style={
                index % 2 === 0
                  ? { ...styles.tableRow, backgroundColor: '#DDDDDD' }
                  : styles.tableRow
              }
            >
              <View style={styles.tableCol}>
                <Text>{formatDates(item.Appointmenttab_date)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_time}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_email}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_doctorname}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const formatDates = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'dd/MM/yyyy');
};

export default AdminPage;