import React,{useEffect, useState} from 'react';
import bgImg from './images/bg_1.jpg';
import image1Img from './images/image_1.jpg';
import image2Img from './images/image_2.jpg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage'
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKuOwNC3-wBj6Gmsx_YGVWV-a_qlX9aXw",
  authDomain: "activecare-8b7d9.firebaseapp.com",
  databaseURL: "https://activecare-8b7d9-default-rtdb.firebaseio.com",
  projectId: "activecare-8b7d9",
  storageBucket: "activecare-8b7d9.appspot.com",
  messagingSenderId: "1028559468034",
  appId: "1:1028559468034:web:e87174fa0ed2459839991b",
  measurementId: "G-ZJETJE99MW"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();


const Pharmacy=()=>{


  
  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const [brandName, setBrandName] = useState('');

  useEffect(() => {
    const fetchBrandName = async () => {
      try {
        const snapshot = await firebase.database().ref('Title/Clinic').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setBrandName(data);
        } else {
          console.error('Brand name not found in database');
        }
      } catch (error) {
        console.error('Error fetching brand name:', error);
      }
    };

    fetchBrandName();
  }, []);


  const [medicines, setMedicines] = useState([]);

  useEffect(() => {
    // Fetch data from Firebase Realtime Database
    const fetchData = async () => {
      const db = firebase.database();
      const ref = db.ref("Pharmacy_Collection");
      ref.on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert object to array
          const medicineArray = Object.values(data).filter(Boolean);
          setMedicines(medicineArray);
        }
      });
    };

    fetchData();

    // Clean up listener on unmount
    return () => {
      const db = firebase.database();
      const ref = db.ref("Pharmacy_Collection");
      ref.off("value");
    };
  }, []);

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const storage = firebase.storage();

      const urls = await Promise.all(medicines.map(async (medicine) => {
        try {
          const url = await storage.ref().child(`Pharmacy_collection/image (${medicine.Medicine_id}).png`).getDownloadURL();
          return { id: medicine.Medicine_id, url };
        } catch (error) {
          console.error('Error fetching image:', error);
          return null;
        }
      }));

      setImageUrls(urls.filter(entry => entry !== null));
    };

    fetchImageUrls();
  }, [medicines]);

  
  useEffect(() => {
  
    $('nav .dropdown').hover(function(){
      var $this = $(this);
      // 	 timer;
      // clearTimeout(timer);
      $this.addClass('show');
      $this.find('> a').attr('aria-expanded', true);
      // $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
      $this.find('.dropdown-menu').addClass('show');
    }, function(){
      var $this = $(this);
        // timer;
      // timer = setTimeout(function(){
        $this.removeClass('show');
        $this.find('> a').attr('aria-expanded', false);
        // $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
        $this.find('.dropdown-menu').removeClass('show');
      // }, 100);
    });
  
  
    $('#dropdown04').on('show.bs.dropdown', function () {
      console.log('show');
    });
  
    // scroll
    var scrollWindow = function() {
      $(window).scroll(function(){
        var $w = $(this),
            st = $w.scrollTop(),
            navbar = $('.ftco_navbar'),
            sd = $('.js-scroll-wrap');
  
        if (st > 150) {
          if ( !navbar.hasClass('scrolled') ) {
            navbar.addClass('scrolled');	
          }
        } 
        if (st < 150) {
          if ( navbar.hasClass('scrolled') ) {
            navbar.removeClass('scrolled sleep');
          }
        } 
        if ( st > 350 ) {
          if ( !navbar.hasClass('awake') ) {
            navbar.addClass('awake');	
          }
          
          if(sd.length > 0) {
            sd.addClass('sleep');
          }
        }
        if ( st < 350 ) {
          if ( navbar.hasClass('awake') ) {
            navbar.removeClass('awake');
            navbar.addClass('sleep');
          }
          if(sd.length > 0) {
            sd.removeClass('sleep');
          }
        }
      });
    };
    scrollWindow();
},[]);

  //user icon
const [dropdownOpen, setDropdownOpen] = useState(false);

const toggleDropdown = () => {
  setDropdownOpen(!dropdownOpen);
};


  //isloggedin
  const [isLoggedIn, setIsLoggedIn] = useState(false);
useEffect(() => {
  // Check if user is already logged in
  const unsubscribe=firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  });
  return unsubscribe
}, []);

//logout
const handleLogout = () => {
  firebase.auth().signOut()
    .then(() => {
      setIsLoggedIn(false);
      // Redirect to home page or do any necessary clean up
      window.location.href = '/';
    })
    .catch((error) => {
      console.error('Sign out error:', error);
    });
};



  //footer address change
  const [officeInfo, setOfficeInfo] = useState({
    email: '',
    phoneNumber: '',
    address: ''
  });

  useEffect(() => {
    const fetchOfficeInfo = async () => {
      try {
        const snapshot = await firebase.database().ref('Address').once('value');
        if (snapshot.exists()) {
          const data = snapshot.val();
          setOfficeInfo({
            email: data.Address_email || '',
            phoneNumber: data.Address_number || '',
            address: data.Address_place || ''
          });
        } else {
          console.error('Office information not found in database');
        }
      } catch (error) {
        console.error('Error fetching office information:', error);
      }
    };

    fetchOfficeInfo();
  }, []);


 


   //appointment form
   const [doctorName, setDoctorName] = useState('');
   const [doctorOptions, setDoctorOptions] = useState([]);
 
   
   useEffect(() => {
    const fetchDoctorOptions = () => {
      const doctorRef = db.ref('doctors_name');
      doctorRef.once('value', (snapshot) => {
        const doctors = snapshot.val();
        if (doctors) {
          const doctorNames = Object.values(doctors);
          setDoctorOptions(doctorNames);
        }
      });
    };
    fetchDoctorOptions();
  }, [db]);

  const [formData, setFormData] = useState({
    Appointmenttab_id: 0, // Initial id value
    Appointmenttab_name: '',
    Appointmenttab_email: '',
    Appointmenttab_date: new Date(),
    Appointmenttab_time: '', // Default time
    Appointmenttab_doctorname: '', 
    Appointmenttab_phone: ''
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [timeOptions1, setTimeOptions1] = useState([]);

  useEffect(() => {
    // Fetch the latest appointment count from the database
    const appointmentsRef = db.ref('Appointment_tab');
    appointmentsRef.once('value', (snapshot) => {
      const appointments = snapshot.val();
      // Get the count of existing appointments
      const count = appointments ? Object.keys(appointments).length : 0;
      setAppointmentCount(count);
    });
  }, []);

  useEffect(() => {
    const fetchTimeOptions = () => {
      if (formData.Appointmenttab_doctorname && formData.Appointmenttab_date) {
        const adminRef = db.ref('adminData');
        adminRef.once('value', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const timeRanges = Object.values(data)
              .filter((item) => item.doctorName === formData.Appointmenttab_doctorname && item.date === formData.Appointmenttab_date.toLocaleDateString('en-US'))
              .map((item) => item.timeRange);
            setTimeOptions1(timeRanges);
          }
        });
      }
    };
    fetchTimeOptions();
  }, [formData.Appointmenttab_doctorname, formData.Appointmenttab_date, db]);
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.toISOString() : '';

    setFormData({ ...formData, Appointmenttab_date: formattedDate });
  };

  
  const handleTimeChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, Appointmenttab_time: value });
  };

  const timeOptions = (() => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push(time);
      }
    }
    return options;
  })();

  const handleDoctorNameChange = (e) => {
    const { value } = e.target;
    setDoctorName(value); // Update doctorName state
    setFormData({ ...formData, Appointmenttab_doctorname: value }); // Update formData state
  };
  


  const handleSubmit = (e) => {
    e.preventDefault();
    // Store form data in Realtime Database

    db.ref('Appointment_tab').orderByChild('Appointmenttab_id').limitToLast(1).once('value')
    .then((snapshot) => {
      let latestAppointment = null;
      snapshot.forEach((childSnapshot) => {
        latestAppointment = childSnapshot.val();
      });

      console.log("Latest appointment:", latestAppointment);

      // Calculate the new ID
      const newAppointmentId = latestAppointment ? latestAppointment.Appointmenttab_id + 1 : 1;

      console.log("New appointment ID:", newAppointmentId);
      const updatedFormData = {
        ...formData,
        Appointmenttab_id: newAppointmentId,
        Appointmenttab_doctorname: doctorName, // Add selected doctor's name
      };

      updatedFormData.Appointmenttab_date = updatedFormData.Appointmenttab_date.toISOString();

      return db.ref('Appointment_tab').push(updatedFormData);
    })
      .then(() => {
        console.log('Form data stored in Realtime Database');
        // Clear form data
        setFormData({
          Appointmenttab_id: '',
          Appointmenttab_name: '',
          Appointmenttab_email: '',
          Appointmenttab_date:  new Date(),
          Appointmenttab_time: '',
          Appointmenttab_doctorname: '',
          Appointmenttab_phone: ''
        });
        setSuccessMessage('Appointment successfully scheduled!');
        // Close the modal
        setTimeout(() => {
          setSuccessMessage('');
          setModalVisible(false);
        }, 2000); // Clear the success message after 3 seconds
        
      })
      .catch((error) => {
        console.error('Error storing form data:', error);
      });
   
  };



    return(
        <>
  <title>DentaCare - Free Bootstrap 4 Template by Colorlib</title>
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700"
    rel="stylesheet"
  />
  <link rel="stylesheet" href="css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="css/animate.css" />
  <link rel="stylesheet" href="css/owl.carousel.min.css" />
  <link rel="stylesheet" href="css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="css/magnific-popup.css" />
  <link rel="stylesheet" href="css/aos.css" />
  <link rel="stylesheet" href="css/ionicons.min.css" />
  <link rel="stylesheet" href="css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="css/jquery.timepicker.css" />
  <link rel="stylesheet" href="css/flaticon.css" />
  <link rel="stylesheet" href="css/icomoon.css" />
  <link rel="stylesheet" href="css/style.css" />
  <nav
    className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
    id="ftco-navbar"
  >
    <div className="container">
      <a className="navbar-brand" href="/">
          {brandName || ''}
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#ftco-nav"
        aria-controls="ftco-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="oi oi-menu" /> Menu
      </button>
      <div className="collapse navbar-collapse" id="ftco-nav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a href="/" className="nav-link">
              Home
            </a>
          </li>
          <li className="nav-item active">
            <a href="/pharmacy" className="nav-link">
              Pharmacy
            </a>
          </li>
          <li className="nav-item">
            <a href="/about" className="nav-link">
              About
            </a>
          </li>
          <li className="nav-item ">
            <a href="/services" className="nav-link">
              Services
            </a>
          </li>
          <li className="nav-item">
            <a href="/doctors" className="nav-link">
              Doctors
            </a>
          </li>

          <li className="nav-item">
            <a href="/contact" className="nav-link">
              Contact
            </a>
          </li>
          <li className="nav-item cta">
            <a
              
              className="nav-link"
              data-toggle="modal"
              data-target="#modalRequest"
              onClick={() => setModalVisible(true)}
            >
              <span>Make an Appointment</span>
            </a>
          </li>
          <div className="quote_btn-container">
      <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <FontAwesomeIcon
          icon={faUser}
          aria-hidden="true"
          style={{ marginRight: '50px', cursor: 'pointer', marginTop: '15px', marginLeft: '15px', color: 'white' }}
        />
        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ backgroundColor: 'white', border: '1px solid black',width: '10', maxHeight: '200px', overflowY: 'auto' }}>
          {isLoggedIn ? (
            <>
              <Link to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }} hover={{backgroundColor:"#007bff"}}>Profile</Link>
              <Link to="/seo-settings" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>SEO Settings</Link>
              <Link to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Admin Page</Link>
              <Link to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Logout</Link>
            </>
          ) : (
            <Link to="/signin" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '12px', color: 'black', backgroundColor: 'transparent' }}>Login</Link>
          )}
        </div>
      </div>
    </div>
        </ul>
      </div>
    </div>
  </nav>
  {/* END nav */}
  <section className=" home-slider">
    <div
      className="slider-item bread-item"
      style={{ backgroundImage: `url(${bgImg})` }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay" />
      <div className="container" data-scrollax-parent="true">
        <div className="row slider-text align-items-end">
          <div className="col-md-7 col-sm-12 ftco-animate mb-5">
            <p
              className="breadcrumbs"
              data-scrollax=" properties: { translateY: '70%', opacity: 1.6}"
            >
              <span className="mr-2">
                <a href="index.html">Home</a>
              </span>{" "}
              <span>Pharmacy</span>
            </p>
            <h1
              className="mb-3"
              data-scrollax=" properties: { translateY: '70%', opacity: .9}"
            >
              Pharmacy Collection
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section">
    <div className="container">
      <div className="row justify-content-center mb-5 pb-5">
        <div className="col-md-7 text-center heading-section ftco-animate">
          <h2 className="mb-2">Pharmacy Collection</h2>
          <p>
            A small river named Duden flows by their place and supplies it with
            the necessary regelialia.
          </p>
        </div>
      </div>
      <div className="row">
      {imageUrls.map(({ id, url }) => (
        <div key={id} className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <img src={url} alt="Medicine" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">{medicines.find(med => med.Medicine_id === id).Medicine_title}</h3>
              <p>Price: {medicines.find(med => med.Medicine_id === id).Medicine_price}</p>
              <p className='text-justify'>{medicines.find(med => med.Medicine_id === id).Medicine_description}</p>
            </div>
          </div>
        </div>
      ))}
        {/*<div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dental-care" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Teeth Cleaning</h3>
              <p>
                Even the all-powerful Pointing has no control about the blind
                texts it is an almost unorthographic.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-tooth-with-braces" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Quality Brackets</h3>
              <p>
                Even the all-powerful Pointing has no control about the blind
                texts it is an almost unorthographic.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-anesthesia" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Modern Anesthetic</h3>
              <p>
                Even the all-powerful Pointing has no control about the blind
                texts it is an almost unorthographic.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dental-care" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Dental Calculus</h3>
              <p>
                Even the all-powerful Pointing has no control about the blind
                texts it is an almost unorthographic.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-bacteria" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Paradontosis</h3>
              <p>
                Even the all-powerful Pointing has no control about the blind
                texts it is an almost unorthographic.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dentist" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Dental Implants</h3>
              <p>
                Even the all-powerful Pointing has no control about the blind
                texts it is an almost unorthographic.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-self-stretch ftco-animate">
          <div className="media block-6 services d-block text-center">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dental-care-1" />
            </div>
            <div className="media-body p-2 mt-3">
              <h3 className="heading">Tooth Braces</h3>
              <p>
                Even the all-powerful Pointing has no control about the blind
                texts it is an almost unorthographic.
              </p>
            </div>
          </div>
        </div>
        */}
      </div>
    </div>
  </section>
  <footer className="ftco-footer ftco-bg-dark ftco-section">
    <div className="container">
      <div className="row mb-5">
        <div className="col-md-3">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">{brandName || ''}</h2>
            <p>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts.
            </p>
          </div>
        
        </div>
        <div className="col-md-2">
          <div className="ftco-footer-widget mb-4 ml-md-5">
            <h2 className="ftco-heading-2">Quick Links</h2>
            <ul className="list-unstyled">
              <li>
                <a href="#" className="py-2 d-block">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Features
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Projects
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Blog
                </a>
              </li>
              <li>
                <a href="#" className="py-2 d-block">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
       
        <div className="col-md-3">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Office</h2>
            <div className="block-23 mb-3">
              <ul>
                <li>
                  <span className="icon icon-map-marker" />
                  <span className="text">
                    {officeInfo.address}
                  </span>
                </li>
                <li>
                  <a href="#">
                    <span className="icon icon-phone" />
                    <span className="text">{officeInfo.phoneNumber}</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="icon icon-envelope" />
                    <span className="text">{officeInfo.email}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <p>
            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            Copyright © All rights reserved | This template is made with{" "}
            <i className="icon-heart" aria-hidden="true" /> by{" "}
            <a href="https://www.datadna.in/" target="_blank">
              DATADNA
            </a>
            {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
          </p>
        </div>
      </div>
    </div>
  </footer>

        {/* Modal */}
  {modalVisible && (
    <div className="modal" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="modalRequestLabel">Make an Appointment</h5>
        <button type="button" className="close" onClick={() => setModalVisible(false)}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" className="form-control" name="Appointmenttab_name" value={formData.Appointmenttab_name} onChange={handleInputChange} placeholder="Full Name" />
          </div>
          <div className="form-group">
            <input type="email" className="form-control" name="Appointmenttab_email" value={formData.Appointmenttab_email} onChange={handleInputChange} placeholder="Email" />
          </div>
          <div className="form-group">
            <input type="tel" className="form-control" name="Appointmenttab_phone" value={formData.Appointmenttab_phone} onChange={handleInputChange} placeholder="Phone Number" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <DatePicker
                  selected={formData.Appointmenttab_date}
                  onChange={(date) => setFormData({ ...formData, Appointmenttab_date: date })}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  placeholderText="Select Date"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select className="form-control" name="Appointmenttab_time" value={formData.Appointmenttab_time} onChange={handleTimeChange} placeholder="Select Time">
                  <option value="">Select Time</option>
                  {timeOptions1.map((time, index) => (
                    <option key={index} value={time}>{time}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                      name="Appointmenttab_doctorname" value={formData.Appointmenttab_doctorname} onChange={handleDoctorNameChange} placeholder="Select Doctor"
                      >
                      {doctorOptions.map((doctor, index) => (
                        <option key={index} style={{ color: 'black' }}>{doctor}</option>
                      ))}
                </select>
                </div>
              </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">Make an Appointment</button>
          </div>
          {successMessage && <p className="text-success">{successMessage}</p>}
        </form>
      </div>
    </div>
  </div>
</div>

  )}
  
  
</>

    );
}
export default Pharmacy;